import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  createLeads,
  getLeads,
  getSpecificLeads,
  updateLeads,
  handleSearch,
  deletePhoto,
  deleteCompanyPhoto,
  getAllLeads,
} from "./thunk";

const initialState = {
  leads: [],
  edit: false,
  lead: null,
  loading: false,
  previous: null,
  next: null,
  loadingUpdated: false,
};

export const leadSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    clearEditLeads: (state, action) => {
      state.lead = state.leads.find((lead) => lead.id === action.payload);
      state.edit = false;
      state.loading = false;
    },
    editLeads: (state, action) => {
      // state.lead = state.leads.find((temp) => temp.id === action.payload);
      state.edit = true;
      // state.loading = false;
    },
    leadClearData: (state) => {
      state.edit = false;
      state.lead = null;
      state.loading = false;
    },
    setLead: (state, action) => {
      state.lead = state.leads.find((lead) => lead.id === action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLeads?.fulfilled, (state, action) => {
      state.loading = false;
      state.leads = action?.payload?.results;
      state.next = action?.payload?.next;
      state.previous = action?.payload?.previous;
      state.count = action?.payload?.count;
    });
    builder.addCase(getLeads.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getAllLeads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllLeads?.fulfilled, (state, action) => {
      state.loading = false;
      state.leads = action?.payload?.results;
      state.next = action?.payload?.next;
      state.previous = action?.payload?.previous;
      state.count = action?.payload?.count;
    });
    builder.addCase(getAllLeads.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getSpecificLeads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSpecificLeads.fulfilled, (state, action) => {
      state.loading = false;
      // state.edit = true;
      state.lead = action.payload;
    });
    builder.addCase(getSpecificLeads.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createLeads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLeads.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createLeads.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateLeads.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateLeads.fulfilled, (state, action) => {
      // let newDocument = state.leads.map((lead) =>
      //   lead.id === action.payload.id ? action.payload : lead
      // );
      // state.leads = newDocument;
      state.edit = false;
      state.loadingUpdated = false;
      // state.lead = null;
      state.loading = false;
    });
    builder.addCase(updateLeads.rejected, (state) => {
      state.loading = false;
      state.loadingUpdated = false;
    });
    builder.addCase(handleSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.leads = action.payload.results;
      state.count = action.payload.count;
      state.next = action.payload.next;
    });
    builder.addCase(handleSearch.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePhoto.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(deletePhoto.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.lead = action.payload;
    });
    builder.addCase(deletePhoto.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addCase(deleteCompanyPhoto.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(deleteCompanyPhoto.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.lead = action.payload;
    });
    builder.addCase(deleteCompanyPhoto.rejected, (state) => {
      state.loadingUpdated = false;
    });
  },
});

export const { clearEditLeads, editLeads, leadClearData, setLead } = leadSlice.actions;
export default leadSlice.reducer;
