import * as API from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";

// async thunk for get report
export const getReport = createAsyncThunk("report/getReport", async (cif, { rejectWithValue }) => {
  try {
    const { data } = await API.getReport(cif);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// async thunk for get users
export const getCustomers = createAsyncThunk("report/getUsers", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getCustomers();
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const handleSearch = createAsyncThunk("report/handleSearch", async (value, { rejectWithValue }) => {
  const { search, postsPerPage, status, loanType, cif } = value;
  try {
    const { data } = await API.handleSearch(search, postsPerPage, status, loanType, cif);
    return data;
  } catch (error) {
    rejectWithValue("Could not find data");
  }
});
