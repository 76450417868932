import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../Components/Modal/Modal";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";

import { errorFunction, successFunction } from "../../../Components/Alert/Alert";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import Nodata from "../../Loan/Pages/NoData/Nodata";
import { editCollateralType } from "../Redux/collateralTypeSlice";
import { deleteCollateralType, getCollateralType, getSpecificCollateralType } from "../Redux/thunk";
import CreateCollateralType from "./CreateCollateralType";

const CollateralTypeListing = ({ masterData }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const { collateralTypes, edit, collateralType } = useSelector((state) => state?.collateralType);

  return (
    <>
      <div>
        {collateralTypes?.length > 0 ? (
          <div className="loan-info py-2  mt-2">
            <div className="table-scrollable">
              <table className="">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>CollateralType</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {collateralTypes?.map((fac, index) => {
                    const { name, id } = fac;
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        <td>
                          <DetailActionButton
                            type={"edit"}
                            onClick={async () => {
                              dispatch(editCollateralType());
                              dispatch(getSpecificCollateralType(id));
                              setCreateModal(true);
                            }}
                          />
                        </td>
                        <td>
                          <DetailActionButton
                            type={"delete"}
                            onClick={async () => {
                              dispatch(deleteCollateralType(id))
                                .unwrap()
                                .then(() => {
                                  successFunction("Collateral Type deleted Successfully.");
                                  dispatch(getCollateralType(postsPerPage));
                                })
                                .catch((error) => {
                                  errorFunction(error);
                                });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Nodata title={"Collateral Type"} onClick={() => setCreateModal(true)} />
        )}
      </div>
      {createModal && (
        <Modal size={"modal-lg"} showModal={createModal} setShowModal={setCreateModal} header={edit ? "Update Collateral Type" : "Collateral Type"} dispatch={setShowModal}>
          <CreateCollateralType masterData={masterData} showModal={createModal} setShowModal={setCreateModal} postsPerPage={postsPerPage} />
        </Modal>
      )}
    </>
  );
};

export default CollateralTypeListing;
