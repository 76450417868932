import { createSlice } from "@reduxjs/toolkit";

import {
  createBusinessQuestion,
  getAllBusinessParagraphQuestion,
  getBooleanBusinessQuestionAnswer,
  getBooleanQuestionnaire,
  getBusinessQuestion,
  getInspectionPdfReoprt,
  getParagraphBusinessQuestionAnswer,
  getTableBusinessQuestionAnswer,
  updateBusinessQuestion,
} from "./thunk";

const initialState = {
  businessParagraphQuestions: [],
  loadingParagraphQuestions: false,
  booleanQuestions: [],
  edit: false,
  businessParagraphQuestion: null,
  loading: false,
  previous: null,
  next: null,
  loadingBusinessQuestion: false,
  loadingMoreBusinessQuestion: false,
  paragraphBusinessQuestionAnswers: [],
  loadingParagraphQuestionsAnswer:false,
  booleanBusinessQuestionAnswers: [],
  loadingBooleanAnswers: false,
  tableBusinessQuestionAnswers: [],
  loadingTableQuestions: false,
  inspectionPdfReport: null,
  loadingPdf: false,
};

export const businessQuestionSlice = createSlice({
  name: "business-question",
  initialState,
  reducers: {
    clearEditBusinessQuestion: (state, action) => {
      // state.loan = state.loans.find((loan) => loan.id === action.payload);
      state.edit = false;
      state.loading = false;
    },
    editBusinessQuestion: (state) => {
      state.edit = true;
    },
    businessQuestionClearData: (state) => {
      state.edit = false;
      state.businessQuestionDetail = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBusinessParagraphQuestion.pending, (state) => {
      state.loadingParagraphQuestions = true;
    });
    builder.addCase(getAllBusinessParagraphQuestion.fulfilled, (state, action) => {
      state.loadingParagraphQuestions = false;
      state.businessParagraphQuestions = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });
    builder.addCase(getAllBusinessParagraphQuestion.rejected, (state) => {
      state.loadingParagraphQuestions = false;
    });
    // for get single business inspection
    builder.addCase(getBusinessQuestion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getBusinessQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.businessParagraphQuestion = action.payload.results;
      state.next = action.payload.next;
    });

    builder.addCase(getBusinessQuestion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createBusinessQuestion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createBusinessQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.businessParagraphQuestion = action.payload;
      state.next = action.payload.next;
    });

    builder.addCase(createBusinessQuestion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBusinessQuestion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateBusinessQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.businessParagraphQuestion = action.payload;
      state.next = action.payload.next;
    });

    builder.addCase(updateBusinessQuestion.rejected, (state) => {
      state.loading = false;
      state.businessParagraphQuestion = null;
      state.loading = false;
    });

    builder.addCase(getBooleanQuestionnaire.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getBooleanQuestionnaire.fulfilled, (state, action) => {
      state.loading = false;
      state.booleanQuestions = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getBooleanQuestionnaire.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getParagraphBusinessQuestionAnswer.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getParagraphBusinessQuestionAnswer.fulfilled, (state, action) => {
      state.loading = false;
      state.paragraphBusinessQuestionAnswers = action.payload.results;
      state.next = action.payload.next;
    });

    builder.addCase(getParagraphBusinessQuestionAnswer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBooleanBusinessQuestionAnswer.pending, (state) => {
      state.loadingBooleanAnswers = true;
    });

    builder.addCase(getBooleanBusinessQuestionAnswer.fulfilled, (state, action) => {
      state.loadingBooleanAnswers = false;
      state.booleanBusinessQuestionAnswers = action.payload.results;
      state.next = action.payload.next;
    });

    builder.addCase(getBooleanBusinessQuestionAnswer.rejected, (state) => {
      state.loadingBooleanAnswers = false;
    });

    builder.addCase(getTableBusinessQuestionAnswer.pending, (state) => {
      state.loadingTableQuestions = true;
    });

    builder.addCase(getTableBusinessQuestionAnswer.fulfilled, (state, action) => {
      state.loadingTableQuestions = false;
      state.tableBusinessQuestionAnswers = action.payload.results;
      state.next = action.payload.next;
    });

    builder.addCase(getTableBusinessQuestionAnswer.rejected, (state) => {
      state.loadingTableQuestions = false;
    });

    builder.addCase(getInspectionPdfReoprt.pending, (state) => {
      state.loadingPdf = true;
    });

    builder.addCase(getInspectionPdfReoprt.fulfilled, (state, action) => {
      state.loadingPdf = false;
      state.inspectionPdfReport = action.payload;
    });

    builder.addCase(getInspectionPdfReoprt.rejected, (state) => {
      state.loadingPdf = false;
    });
  },
});

export const { clearEditBusinessQuestion, editBusinessQuestion, businessQuestionClearData } = businessQuestionSlice.actions;
export default businessQuestionSlice.reducer;
