import React, { useEffect, useRef, useState } from "react";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../Utils/Hooks/useDebounce";
import { getCustomers, handleSearch } from "../Redux/thunk";
import "./report.css";
import CustomerListing from "./CustomerListing";

const types = "reports";
const title = "Customer 360°";
const CustomerDetails = () => {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.layout);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(null);
  const [loanType, setLoanType] = useState(null);
  const debouncedSearch = useDebounce(search, 500);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);

  const isFirstRender = useRef(true);
  const { customers, next, loading, count } = useSelector((state) => state.report);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    let value = {
      search: debouncedSearch,
      status,
      postsPerPage,
      loanType,
    };

    dispatch(handleSearch(value));
    // }
  }, [dispatch, postsPerPage, debouncedSearch, status, loanType]);

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  return (
    <>
      <div className="lead-wrapper">
        <CommonPageHeader
          title={title}
          dispatch={dispatch}
          showModal={showModal}
          types={types}
          search={search}
          setSearch={setSearch}
          status={status}
          setStatus={setStatus}
          loanType={loanType}
          setLoanType={setLoanType}
          loading={loading}
          count={customers?.length || count}
          data={customers}
        />

        <CustomerListing setSearch={setSearch} />
      </div>
    </>
  );
};

export default CustomerDetails;
