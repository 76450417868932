import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

export const getCollateralType = createAsyncThunk("collateralType/getCollateralType", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getCollateralType(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Collateral Type.");
  }
});

export const getAllCollateralType = createAsyncThunk("collateralType/getAllCollateralType", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllCollateralType();
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Collateral Type.");
  }
});
export const getSpecificCollateralType = createAsyncThunk("collateralType/getSpecificCollateralType", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getSpecificCollateralType(id);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get CollateralType type");
  }
});

export const getPrevious = createAsyncThunk("collateralType/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Collateral Type.");
  }
});

export const getNext = createAsyncThunk("collateralType/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Collateral Type.");
  }
});

export const createCollateralType = createAsyncThunk("collateralType/createCollateralType", async (data, { rejectWithValue, dispatch }) => {
  const { name } = data;
  try {
    const body = JSON.stringify({ name });
    const { data } = await API.createCollateralType(body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to create collateralType.");
  }
});
export const updateCollateralType = createAsyncThunk("collateralType/updateCollateralType", async (data, { rejectWithValue, dispatch }) => {
  const { id, name } = data;

  try {
    const body = JSON.stringify({ name });
    const { data } = await API.updateCollateralType(id, body);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to create collateralType.");
  }
});
export const deleteCollateralType = createAsyncThunk("collateralType/deleteCollateralType", async (id, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await API.deleteCollateralType(id);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to Delete collateralType.");
  }
});

export const handleSearch = createAsyncThunk("collateralType/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    rejectWithValue("Failed to get collateralType");
  }
});
