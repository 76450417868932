import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonCreateButton from "../../../Components/CommonCreateButton/CommonCreateButton.js";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import ListingSkeleton from "../../../Components/Skeleton/ListingSkeleton";
import { setShowModal } from "../../../Redux/Layout/layoutSlice.js";
import useDebounce from "../../../Utils/Hooks/useDebounce";
import { PERMISSIONS } from "../../../Utils/permission.js";
import { getOrganization, handleSearch } from "../Redux/thunk";
import CreateOrganization from "./CreateOrganization";
import Organization from "./Organization";
import "./organization.css";

const Modal = lazy(() => import("../../../Components/Modal/Modal"));

const title = "Organization";
const types = "organization";

const OrganizationListing = () => {
  const dispatch = useDispatch();
  const loadingOrganization = useSelector((state) => state.organization.loadingOrganization);
  const organizations = useSelector((state) => state.organization.organizations);
  const count = useSelector((state) => state.organization.count);
  const edit = useSelector((state) => state.organization.edit);
  const { showModal } = useSelector((state) => state.layout);
  const [showUserSetupModal, setShowUserSetupModal] = useState(false);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const addOrganizationPermission = isSuperuser || permissions.includes(PERMISSIONS.organizationSetup.createOrganizationSetup);

  const [search, setSearch] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(20);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (search === "") {
      dispatch(getOrganization(postsPerPage));
    } else {
      dispatch(handleSearch({ search, postsPerPage }));
    }
  }, [postsPerPage, debouncedSearch, dispatch, search]);

  return (
    <>
      <div className="organization-wrapper">
        <CommonPageHeader
          title={title}
          showModal={showModal}
          types={types}
          search={search}
          setSearch={setSearch}
          loading={loadingOrganization}
          data={organizations}
          count={count}
        />

        {loadingOrganization && <ListingSkeleton />}
        {!loadingOrganization && <Organization dispatch={dispatch} showModal={showModal} setPostsPerPage={setPostsPerPage} setShowUserSetupModal={setShowUserSetupModal} />}
      </div>
      <CommonCreateButton types={types} showModal={showUserSetupModal} setShowModal={setShowUserSetupModal} permission={addOrganizationPermission} />
      {addOrganizationPermission && showUserSetupModal && (
        <Suspense fallback={<div></div>}>
          <Modal
            dispatch={setShowModal}
            showModal={showUserSetupModal}
            setShowModal={setShowUserSetupModal}
            header={edit ? "Update Organization" : "Add Organization"}
            types={types}
            edit={edit}
            size={"modal-xl"}
          >
            <CreateOrganization dispatch={dispatch} showModal={showUserSetupModal} postsPerPage={postsPerPage} setShowUserSetupModal={setShowUserSetupModal} />
          </Modal>
        </Suspense>
      )}
    </>
  );
};

export default OrganizationListing;
