import axiosInstance from "../../../../../../Utils/axios";

export const getAllBusinessInspection = (postsPerPage) => axiosInstance.get(`api/v1/loan-inspection-app/business-inspection?offset=0&limit=${postsPerPage}&status=&ordering=-id`);
// create loan api: /api/v1/lead-app/loan-lead/

export const getBusinessInspection = (id) => axiosInstance.get(`api/v1/loan-inspection-app/business-inspection?loan=${id}&limit=20&ordering=-id `);

export const createBusinessInspection = (data) =>
  axiosInstance.post(`api/v1/loan-inspection-app/business-inspection`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const updateBusinessInspection = (id, data) => axiosInstance.patch(`api/v1/loan-inspection-app/business-inspection/${id}`, data);
