import * as API from "./api";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Common error handling function
const handleErrors = (error) => {
  return error?.response?.data?.msg[0] || error;
};

// Helper function to create thunks
const createThunk = (actionType, apiMethod, transformData = (data) => data) => {
  return createAsyncThunk(actionType, async (arg, { rejectWithValue }) => {
    try {
      const { data } = await apiMethod(arg);
      return transformData(data);
    } catch (error) {
      return rejectWithValue(handleErrors(error));
    }
  });
};

// Use the helper function to create thunks
export const getQuestions = createThunk("inspection/getQuestions", API.getQuestions);
export const getInspections = createAsyncThunk("inspection/getInspections", async (value, { rejectWithValue }) => {
  const { postsPerPage, status, loanType ,startDate,endDate,collateralType} = value;

  
  try {
    const { data } = await API.getInspections(postsPerPage, status, loanType,startDate,endDate,collateralType);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const approveInspection = createThunk("inspection/approveInspection", API.approveInspection);

export const getSpecificInspection = createThunk("inspection/getSpecificInspection", API.getSpecificInspection);

export const getSpecificQuestion = createThunk("inspection/getSpecificQuestion", API.getSpecificQuestion);

export const getAllQuestions = createThunk("inspection/getAllQuestions", API.getAllQuestions);

export const createQuestion = createThunk(
  "inspection/createQuestion",
  API.createQuestion,
  (data) => data?.map((question) => ({ ...question, isActive: true }))
);

export const updateQuestion = createThunk("inspection/updateQuestion", API.updateQuestion);
export const getAllCheckListQuestions = createThunk("inspection/getAllCheckListQuestions", API.getAllCheckListQuestions);
export const getAllCheckListQuestionsAnswers = createThunk("inspection/getAllCheckListQuestionsAnswers", API.getAllCheckListQuestionsAnswers);
export const createCheckList = createThunk(
  "inspection/createCheckList",
  API.createCheckList,
  (data) => data?.map((question) => ({ ...question, isActive: true }))
);

export const getQuestionsAnswers = createThunk("inspection/getQuestionsAnswers", API.getQuestionsAnswers);
export const getSpecificQuestionAnswer = createThunk("inspection/getSpecificQuestionAnswer", API.getSpecificQuestionAnswer);
export const getAllQuestionsAnswer = createThunk("inspection/getAllQuestionsAnswers", API.getAllQuestionsAnswers);
export const createQuestionAnswer = createThunk("inspection/createQuestionAnswer", API.createQuestionAnswer);
export const updateQuestionAnswer = createThunk("inspection/updateQuestionAnswer", API.updateQuestionAnswer);
export const handleSearch = createAsyncThunk(
  "inspection/handleSearch",
  async (value, { rejectWithValue }) => {
    const { search, postsPerPage, status, loanType } = value;
    try {
      const { data } = await API.handleSearch(search, postsPerPage, status, loanType);
      return data;
    } catch (error) {
      rejectWithValue("Could not find data");
    }
  }
);
