import axiosInstance from "../../../Utils/axios";

export const loadBusinessNature = async (search, loadOptions, { limit, offset }) => {
  const { data } = await axiosInstance(
    `api/v1/lead-app/business-nature?search=${search}&offset=${offset}&limit=${limit}`
  );

  return {
    options: data.results,
    hasMore: data.next ? true : false,
    additional: {
      offset: data.next ? offset + 10 : offset,
      limit: 10,
    },
  };
};
export const loadFacilityType = async (search, loadOptions, { limit, offset }) => {
  const { data } = await axiosInstance(
    `api/v1/lead-app/facility-type?search=${search}&offset=${offset}&limit=${limit}`
  );

  return {
    options: data.results,
    hasMore: data.next ? true : false,
    additional: {
      offset: data.next ? offset + 10 : offset,
      limit: 10,
    },
  };
};
export const loadReason = async (search, loadOptions, { limit, offset }) => {
  const { data } = await axiosInstance(`api/v1/lead-app/reason/?search=${search}&offset=${offset}&limit=${limit}`);

  return {
    options: data.results,
    hasMore: data.next ? true : false,
    additional: {
      offset: data.next ? offset + 10 : offset,
      limit: 10,
    },
  };
};
export const loadCollateralType = async (search, loadOptions, { limit, offset }) => {
  const { data } = await axiosInstance(
    `api/v1/lead-app/collateral_type?search=${search}&offset=${offset}&limit=${limit}`
  );

  return {
    options: data.results,
    hasMore: data.next ? true : false,
    additional: {
      offset: data.next ? offset + 10 : offset,
      limit: 10,
    },
  };
};
export const loadUser = async (search, loadOptions, { limit, offset }) => {
  const { data } = await axiosInstance(
    `api/v1/lead-app/collateral_type?search=${search}&offset=${offset}&limit=${limit}`
  );

  return {
    options: data.results,
    hasMore: data.next ? true : false,
    additional: {
      offset: data.next ? offset + 10 : offset,
      limit: 10,
    },
  };
};

const createLoader =
  (path) =>
  async (search, { limit, offset }) => {
    const { data } = await axiosInstance(`${path}?search=${search}&offset=${offset}&limit=${limit}`);
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.count > offset ? offset + 10 : offset,
        limit: 10,
      },
    };
  };

export const loadDepartment = createLoader("api/v1/lead-app/department-list");
export const loadBranch = createLoader("api/v1/lead-app/hierarchy-list");
export const loadLoanProductType = createLoader("api/v1/lead-app/product-type");
export const loadDistrict = createLoader("api/v1/core-app/district");
export const loadPalika = createLoader("api/v1/core-app/palika");
export const loadProvince = createLoader("api/v1/core-app/province");
// export const loadBusinessNature = createLoader("api/v1/lead-app/business-nature");
export const loadUsers = createLoader("api/v1/lead-app/facility-type");
// export const loadCollateralType = createLoader("api/v1/lead-app/collateral_type");
// export const loadReason = createLoader("api/v1/lead-app/reason/");
