import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getOrganizationHierarchy,
  getAllOrganizationHierarchy,
  getNext,
  getPrevious,
  getPageOrganizationHierarchy,
  createOrganizationHierarchy,
  updateOrganizationHierarchy,
  handleSearch,
} from "./thunk";

const initialState = {
  organizationHierarchies: [],
  edit: false,
  organizationHierarchy: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  parent: null,
  loadingUpdated: false,
  loadingOrganizationHierarchy: false,
};

export const organizationHierarchySlice = createSlice({
  name: "organizationHierarchy",
  initialState,
  reducers: {
    organizationHierarchyEditSuccess: (state, action) => {
      state.edit = true;
      state.organizationHierarchy = state.organizationHierarchies.find(
        (org) => org.id === action.payload
      );
    },
    addOrganizationHierarchy: (state, action) => {
      state.parent = action.payload;
    },
    clearEditOrganizationHierarchy: (state) => {
      state.edit = false;
      state.organizationHierarchy = null;
      state.parent = null;
      state.loadingUpdated = false;
      state.loadingOrganizationHierarchy = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizationHierarchy.pending, (state) => {
      state.loadingOrganizationHierarchy = true;
    });
    builder.addCase(getOrganizationHierarchy.fulfilled, (state, action) => {
      state.loadingOrganizationHierarchy = false;
      state.edit = false;
      state.organizationHierarchies = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getOrganizationHierarchy.rejected, (state) => {
      state.loadingOrganizationHierarchy = false;
      state.edit = false;
    });
    builder.addCase(createOrganizationHierarchy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrganizationHierarchy.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createOrganizationHierarchy.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateOrganizationHierarchy.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateOrganizationHierarchy.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateOrganizationHierarchy.rejected, (state) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addMatcher(
      isAnyOf(
        getAllOrganizationHierarchy.pending,
        getPrevious.pending,
        getNext.pending,
        getPageOrganizationHierarchy.pending,
        handleSearch.pending
      ),
      (state) => {
        state.loadingOrganizationHierarchy = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllOrganizationHierarchy.fulfilled,
        getPrevious.fulfilled,
        getNext.fulfilled,
        getPageOrganizationHierarchy.fulfilled,
        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loadingOrganizationHierarchy = false;
        state.organizationHierarchies = action.payload.results;
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllOrganizationHierarchy.rejected,
        getPrevious.rejected,
        getNext.rejected,
        getPageOrganizationHierarchy.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loadingOrganizationHierarchy = false;
      }
    );
  },
});
export const {
  addOrganizationHierarchy,
  organizationHierarchyEditSuccess,
  clearEditOrganizationHierarchy,
} = organizationHierarchySlice.actions;
export default organizationHierarchySlice.reducer;
