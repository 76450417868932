import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSystemActivities = createAsyncThunk("systemActivity/getAllSystemActivities", async (value, { rejectWithValue }) => {
  const { postsPerPage, loanType, status } = value;
  try {
    const { data } = await API.getSystemActivities(postsPerPage, loanType, status);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});