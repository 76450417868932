import React, { useState } from "react";
import { useSelector } from "react-redux";
// import LoanTab from "./tabs/LoanTab";
import { FiDownload } from "react-icons/fi";
import { MdOutlineFitbit } from "react-icons/md";
import dummyUser from "../../../assets/dummy-user.png";
import CollateralTab from "./tabs/CollateralTab";
import InspectionTab from "./tabs/InspectionTab";
import LoanTab from "./tabs/LoanTab";

const ReportPage = ({ data, setPostsPerPage, setShowModal, postsPerPage, search }) => {
  const { basicDetails, loan, collateral, inspection, filePath } = data;

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const viewPermission = permissions.includes("view_loan_info") || isSuperuser;
  const { loading, error, count, next } = useSelector((state) => state.report);

  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 1) {
      if (next) {
        scrollToEnd();
      }
    }
  };
  const [accountType, setAccountType] = useState("All");
  const [loanType, setLoanType] = useState("All");
  const newJson = {
    name: "Shikhar Manandhar",
    email: "shikhar.manandhar@gmail.com",
    address: "123 Main Street, New York, NY 10012",
    contact: "1234567890",
    contact2: "1234567890",
    accountNo: "1234567890",
    report: "https://www.google.com",
    cif: "1234567890",
    totalBalance: 1000,
    totalLoanAmount: 1000,
    totalDueAmount: 1000,
    photo:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    accounts: [
      {
        id: 1,
        name: "Account 1",
        type: "Current",
        balance: 2000,
        loans: [
          {
            id: 1,
            loanAmount: 1000,
            interest: 10,
            dueAmount: 1000,
            loanType: "OD",
            collateral: [
              {
                id: 1,
                collateralType: "Land",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
                inspection: [
                  {
                    id: 1,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                  {
                    id: 2,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                ],
              },
              {
                id: 2,
                collateralType: "Building",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
              },
            ],
          },
          {
            id: 2,
            loanAmount: 5000,
            interest: 10,
            dueAmount: 11111,
            loanType: "OD",
            collateral: [
              {
                id: 3,
                collateralType: "Land",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
                inspection: [
                  {
                    id: 1,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                  {
                    id: 2,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                ],
              },
              {
                id: 4,
                collateralType: "Building",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        name: "Account 2",
        type: "Current",
        balance: 2000,
        loans: [
          {
            id: 3,
            loanAmount: 1000,
            interest: 10,
            dueAmount: 1000,
            loanType: "Home Loan",
            collateral: [
              {
                id: 1,
                collateralType: "Land",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
                inspection: [
                  {
                    id: 1,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                  {
                    id: 2,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                ],
              },
              {
                id: 2,
                collateralType: "Building",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
              },
            ],
          },
          {
            id: 4,
            loanAmount: 5000,
            interest: 10,
            dueAmount: 11111,
            loanType: "OD",
            collateral: [
              {
                id: 1,
                collateralType: "Land",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
                inspection: [
                  {
                    id: 1,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                  {
                    id: 2,
                    inspectionNumber: "INS-1234567890",
                    dateOfInspection: "2022-01-01",
                    inspectionType: "Suprised",
                    assigned: "Shikhar Manandhar",
                  },
                ],
              },
              {
                id: 2,
                collateralType: "Building",
                ownedBy: "Shikhar Manandhar",
                district: "New York",
                area: "New York",
                palika: "10012",
                wardNo: "100",
                plotNo: 2545454,
                address: "123 Main Street, New York, NY 10012",
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <>
      {/* {basicDetails && basicDetails.length > 0 ? (
        <div className="employee-details-container">
          <div className="row">
            <div className="col-4">
              <p>Nothing Here</p>
            </div>
            <div className="col-4">
              <div className="col-8 official-details">
                <h2>{basicDetails[0]?.customerName}</h2>
                <p>
                  Email: <strong>{basicDetails[0].customerEmail ? basicDetails[0]?.customerEmail : "N/A"}</strong>
                </p>
                <p>
                  Address:
                  <strong>{basicDetails[0]?.customerAddress ? basicDetails[0]?.customerAddress : "N/A"}</strong>
                </p>
                <p>
                  Contact:
                  <strong>
                    {basicDetails[0]?.primaryContactNumber ? basicDetails[0]?.primaryContactNumber : "N/A"}
                  </strong>
                </p>
                <p>
                  Contact 2:
                  <strong>
                    {basicDetails[0]?.secondaryContactNumber ? basicDetails[0]?.secondaryContactNumber : "N/A"}
                  </strong>
                </p>
                <p>
                  Account Number:
                  <strong> {basicDetails[0]?.accountNo ? basicDetails?.accountNo : "N/A"}</strong>
                </p>
                <p>
                  Report:
                  <a href={`${process.env.REACT_APP_BASE_URL}${filePath}`} download title="download report">
                    <FiDownload size={25} className="ml-2" color={"#008eb0"} strokeWidth={3} />
                  </a>
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="profile">
                <img src={basicDetails[0].applicantId || dummyUser} alt="user profile" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="no-data-wrapper mt-2 flex-column border p-4 d-flex justify-content-center align-items-center"
          style={{ height: "90vh" }}
        >
          <MdOutlineFitbit size={64} color="#008eb0" />
          <h5 className="p-2">No Data Found For given CIF: {search}</h5>
          <p className="">Search available CIF and it will show up here.</p>
        </div>
      )}
      {basicDetails && basicDetails.length > 0 && (
        <div className="row">
          <div className="col-4">
            <LoanTab postsPerPage={postsPerPage} setPostsPerPage={setPostsPerPage} loans={loan} />
          </div>
          <div className="col-4">
            <CollateralTab collateral={collateral} />
          </div>
          <div className="col-4">
            <InspectionTab inspection={inspection} />
          </div>
        </div>
      )} */}
      {basicDetails && basicDetails.length > 0 && (
        <div className="row m-0">
          <div className="employee-details-container col-3">
            <div className="d-flex flex-column">
              <div className="">
                <p className="m-0 mb-1" style={{ color: "#008eb0", fontSize: "12px", fontWeight: "500" }}>
                  Personal Information
                </p>

                <div className="official-details">
                  <h4 className="">{basicDetails[0]?.customerName}</h4>
                  <div className="m-0 d-flex justify-content-between ">
                    <p className="m-0 font-gray ">Email:</p>
                    <p className="m-0">{basicDetails[0]?.customerEmail}</p>
                  </div>
                  <div className="m-0 d-flex justify-content-between ">
                    <p className="m-0 font-gray">Address:</p>
                    <p className="m-0">{basicDetails[0]?.customerAddress}</p>
                  </div>
                  <div className="m-0 d-flex justify-content-between ">
                    <p className="m-0 font-gray">Primary Contact:</p>
                    <p className="m-0">{basicDetails[0]?.primaryContactNumber}</p>
                  </div>
                  {basicDetails[0]?.secondaryContactNumber && (
                    <div className="m-0 d-flex justify-content-between ">
                      <p className="m-0 font-gray">Secondary Contact:</p>
                      <p className="m-0">{basicDetails[0]?.secondaryContactNumber}</p>
                    </div>
                  )}

                  <div className="m-0 d-flex justify-content-between ">
                    <p className="m-0 font-gray">Report:</p>
                    <a
                      style={{ color: "#008eb0" }}
                      href={`${process.env.REACT_APP_BASE_URL}${filePath}`}
                      download
                      title="download report"
                    >
                      Download Report
                    </a>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="">
                <p className="m-0 mb-1" style={{ color: "#008eb0", fontSize: "12px", fontWeight: "500" }}>
                  Accounts
                </p>
                <div className="d-flex flex-wrap mt-2" style={{ gap: "10px" }}>
                  <button
                    className={
                      accountType === "All"
                        ? "btn background-color-primary text-white"
                        : "btn border-color-primary text-primary "
                    }
                    onClick={() => {
                      setAccountType("All");
                      setLoanType("All");
                    }}
                  >
                    Overall
                  </button>
                  {newJson.accounts.map((account, index) => {
                    return (
                      <button
                        key={index + 1}
                        className={
                          accountType === account?.name
                            ? "btn background-color-primary text-white"
                            : "btn border-color-primary text-primary  "
                        }
                        onClick={() => {
                          setAccountType(account?.name);
                        }}
                      >
                        {account.name}
                      </button>
                    );
                  })}
                </div>
                {accountType !== "All" ? (
                  <div className="mt-2 official-details">
                    <div className="m-0 d-flex justify-content-between ">
                      <p className="m-0 font-gray">Balance:</p>
                      <p className="m-0">{newJson.accounts.find((account) => account.name === accountType)?.balance}</p>
                    </div>
                    <div className="m-0 d-flex justify-content-between ">
                      <p className="m-0 font-gray">Loan Amount:</p>
                      <p className="m-0">
                        {newJson.accounts
                          .filter((account) => account.name === accountType)
                          .reduce(
                            (total, account) =>
                              total + account.loans.reduce((loanTotal, loan) => loanTotal + (loan.loanAmount || 0), 0),
                            0
                          )}
                      </p>
                    </div>
                    <div className="m-0 d-flex justify-content-between ">
                      <p className="m-0 font-gray">Due Amount:</p>
                      <p className="m-0">
                        {" "}
                        {newJson.accounts
                          .filter((account) => account.name === accountType)
                          .reduce(
                            (total, account) =>
                              total + account.loans.reduce((dueTotal, loan) => dueTotal + (loan.dueAmount || 0), 0),
                            0
                          )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-2 official-details">
                    <div className="m-0 d-flex justify-content-between ">
                      <p className="m-0 font-gray">Balance:</p>
                      <p className="m-0">{newJson.totalBalance}</p>
                    </div>
                    <div className="m-0 d-flex justify-content-between ">
                      <p className="m-0 font-gray">Loan Amount:</p>
                      <p className="m-0">{newJson.totalLoanAmount}</p>
                    </div>
                    <div className="m-0 d-flex justify-content-between ">
                      <p className="m-0 font-gray">Due Amount:</p>
                      <p className="m-0"> {newJson.totalDueAmount}</p>
                    </div>
                  </div>
                )}
              </div>
              <hr></hr>
              <div className="">
                <p className="m-0 mb-1" style={{ color: "#008eb0", fontSize: "12px", fontWeight: "500" }}>
                  Profile
                </p>
                <div className="profile mt-2">
                  <img src={basicDetails[0].applicantId || dummyUser} alt="user profile" />
                </div>
              </div>
            </div>
          </div>

          {basicDetails && basicDetails.length > 0 && (
            <div className="row m-0 col-9 pr-0">
              <div className="col-12 p-0">
                <LoanTab
                  accountType={accountType}
                  newJson={newJson}
                  postsPerPage={postsPerPage}
                  setPostsPerPage={setPostsPerPage}
                  loans={loan}
                  loanType={loanType}
                  setLoanType={setLoanType}
                />
              </div>
              <div className="col-7 p-0">
                <CollateralTab
                  loanType={loanType}
                  setLoanType={setLoanType}
                  accountType={accountType}
                  json={newJson}
                  collateral={collateral}
                />
              </div>
              <div className="col-5 pr-0">
                <InspectionTab accountType={accountType} json={newJson} inspection={inspection} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ReportPage;
