import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Layout from "../Components/Layout";
import PageNotFound from "../Components/PageNotFound";
import { closeModal } from "../Redux/Layout/layoutSlice";
import { PERMISSIONS } from "../Utils/permission";
import ProtectedRoute from "./ProtectedRoute";

// Eager imports
import BusinessNature from "../Pages/BusinessNature/Pages";
import CollateralType from "../Pages/CollateralType/Pages";
import Country from "../Pages/Country/Page";
import Currency from "../Pages/Currency/Page";
import CustomerDetails from "../Pages/CustomerDetails/Pages";
import Dashboard from "../Pages/Dashboard/Pages";
import Facilities from "../Pages/Facilities/Pages";
import FiscalSessionAD from "../Pages/FiscalSessionAD/Page";
import FiscalSessionBS from "../Pages/FiscalSessionBS/Page";
import Organization from "../Pages/Organization/Page";
import SystemActivity from "../Pages/SystemActivity/Page";
import User from "../Pages/User/Page";
import UserGroup from "../Pages/UserGroup/Page";

// Lazy imports
const DepartmentListing = lazy(() => import("../Pages/Department/Page"));
const CoreSetupTabs = lazy(() => import("../Components/Tab/CoreSetupTab"));
const LoanSetupTabs = lazy(() => import("../Components/Tab/LoanSetupTab"));
const UserTabs = lazy(() => import("../Components/Tab/UserTab"));
const HierarchyListing = lazy(() => import("../Pages/OrganizationHierarchy/Page"));
const ChangePassword = lazy(() => import("../Pages/User/ResetPassword/ChangePassword"));
const Inspection = lazy(() => import("../Pages/CollateralInspection/Pages"));
const BusinessInspection = lazy(() => import("../Pages/BusinessInspection/Pages"));
const Loan = lazy(() => import("../Pages/Loan/Pages"));
const AnnexTab = lazy(() => import("../Components/Tab/CollateralQuestionnaireTab"));
const BusinessAnnexTab = lazy(() => import("../Components/Tab/BusinessQuestionnaireTab"));
const CollectionAndRecovery = lazy(() => import("../Pages/CollectionAndRecovery/Page"));

const PrivateRoute = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleRefresh = () => dispatch(closeModal());
    window.addEventListener("beforeunload", handleRefresh);
    return () => window.removeEventListener("beforeunload", handleRefresh);
  }, [dispatch]);

  return (
    <Suspense fallback="">
      <Layout>
        <Switch>
          {/* Sidebar related routes */}
          <ProtectedRoute exact path="/" component={Dashboard} permission="" />
          <ProtectedRoute exact path="/client-registration" component={Loan} permission={PERMISSIONS?.loanInfo.all} />
          <ProtectedRoute exact path="/collateral-inspection" component={Inspection} permission={PERMISSIONS.inspection.all} />
          <ProtectedRoute exact path="/buisness-inspection" component={BusinessInspection} permission={PERMISSIONS?.businessInspection.all} />
          {/* <ProtectedRoute exact path="/collection-and-recovery" component={CollectionAndRecovery} permission={PERMISSIONS?.recoveryCollectionLoan.all} /> */}
          <ProtectedRoute exact path="/customer-details" component={CustomerDetails} permission={PERMISSIONS?.loanInfo.all} /> {/* TODO: add permission */}
          <ProtectedRoute exact path="/system-activity" component={SystemActivity} permission={PERMISSIONS?.systemActivity.all} />
          {/* Settings related routes */}
          <ProtectedRoute exact path="/department" component={DepartmentListing} permission={PERMISSIONS?.department.all} />
          <ProtectedRoute exact path="/hierarchy" component={HierarchyListing} permission={PERMISSIONS?.user.all} />
          <ProtectedRoute exact path="/collateral-questionnaires" component={AnnexTab} permission={PERMISSIONS?.annex.all} />
          <ProtectedRoute exact path="/business-questionnaires" component={BusinessAnnexTab} permission={PERMISSIONS.businessInspectionAnnexure.all} />
          <ProtectedRoute exact path="/core-setup" component={CoreSetupTabs} permission={PERMISSIONS?.organizationSetup.all} />
          <ProtectedRoute exact path="/organization-setup" component={Organization} permission={PERMISSIONS.organizationSetup.all} />
          <ProtectedRoute exact path="/country" component={Country} permission={PERMISSIONS.country.all} />
          <ProtectedRoute exact path="/currency" component={Currency} permission={PERMISSIONS.currency.all} />
          <ProtectedRoute exact path="/fiscalSessionAD" component={FiscalSessionAD} permission={PERMISSIONS?.fiscalSessionAD.all} />
          <ProtectedRoute exact path="/fiscalSessionBS" component={FiscalSessionBS} permission={PERMISSIONS?.fiscalSessionBS.all} />
          <ProtectedRoute exact path="/user-setup" component={UserTabs} permission={[""]} />
          <ProtectedRoute exact path="/user" component={User} permission={PERMISSIONS.user.all} />
          <ProtectedRoute exact path="/user-group" component={UserGroup} permission={PERMISSIONS.user.all} />
          <ProtectedRoute exact path="/loan-setup" component={LoanSetupTabs} permission={PERMISSIONS.loanInfo.all} />
          <ProtectedRoute exact path="/facility-type" component={Facilities} permission={PERMISSIONS.facilityType.all} />
          <ProtectedRoute exact path="/business-nature" component={BusinessNature} permission={PERMISSIONS.collateralType.all} /> {/* TODO: add permission */}
          <ProtectedRoute exact path="/collateral-type" component={CollateralType} permission={PERMISSIONS.collateralType.all} />
          {/* <ProtectedRoute exact path="/change-password" component={ChangePassword} permission={PERMISSIONS?.user.all} /> */}
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Layout>
    </Suspense>
  );
};

export default PrivateRoute;
