import React, { useState, lazy, useRef } from "react";
import ColumnResize from "react-table-column-resizer";
import { useDispatch, useSelector } from "react-redux";
import { currencyEditSuccess } from "../Redux/currencySlice";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import Status from "../../../Components/Status";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";

const Currency = ({ dispatch, setPostsPerPage, setShowCountryModal }) => {
  const listRef = useRef(null);
  const next = useSelector((state) => state.currency.next);
  const currencies = useSelector((state) => state.currency.currencies);

  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (
      event.currentTarget.scrollTop + event.currentTarget.offsetHeight ===
      event.currentTarget.scrollHeight
    ) {
      if (next) {
        scrollToEnd();
      }
    }
  };

  const handleEdit = (id) => {
    dispatch(currencyEditSuccess(id));
    setShowCountryModal(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-12 table-scrollable mt-2" onScroll={handleScroll} ref={listRef}>
          <table className="listing-table">
            <thead>
              <tr>
                <th>SN</th>
                <ColumnResize id={1} className="columnResizer" />
                <th>Name</th>
                <ColumnResize id={2} className="columnResizer" minWidth={120} />
                <th>Symbol</th>
                <ColumnResize id={3} className="columnResizer" minWidth={120} />
                <th>Code</th>
                <ColumnResize id={4} className="columnResizer" minWidth={120} />
                <th>Active</th>
                <ColumnResize id={5} className="columnResizer" minWidth={120} />
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currencies?.map((currency, i) => {
                const { id, name, symbol, code, active } = currency;

                return (
                  <tr key={id} onDoubleClick={() => handleEdit(id)} style={{ cursor: "pointer" }}>
                    <td>{i + 1}</td>
                    <td className="column_resizer_body" />
                    <td>{name ? name : "N/A"}</td>
                    <td className="column_resizer_body" />
                    <td>{symbol ? symbol : "N/A"}</td>
                    <td className="column_resizer_body" />
                    <td>{code ? code : "N/A"}</td>
                    <td className="column_resizer_body" />
                    <td>
                      <Status active={active} />
                    </td>
                    <td className="column_resizer_body" />
                    <td>
                      <DetailActionButton type={"edit"} onClick={() => handleEdit(id)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Currency;
