import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { errorFunction, successFunction } from "../../../Components/Alert/Alert";
import { createBusinessNature, getBusinessNature, updateBusinessNature } from "../Redux/thunk";
import TextField from "../../../Components/CommonTextField";

const CreateBusinessNature = ({ masterData, showModal, setShowModal, postsPerPage }) => {
  const dispatch = useDispatch();

  const { businessNature, edit } = useSelector((state) => state?.businessNature);
  const initialState = {
    name: edit ? businessNature?.name : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const createdData = {
      ...values,
    };
    const updatedData = {
      id: businessNature?.id,
      ...values,
    };
    if (edit) {
      dispatch(updateBusinessNature(updatedData))
        .unwrap()
        .then(() => {
          successFunction("BusinessNature Updated Successfully.");
          dispatch(getBusinessNature(postsPerPage));
          setShowModal(false);
        })
        .catch((error) => {
          errorFunction(error);
        });
    } else {
      dispatch(createBusinessNature(createdData))
        .unwrap()
        .then(() => {
          successFunction("BusinessNature Created Successfully.");
          dispatch(getBusinessNature(postsPerPage));
          setShowModal(false);
        })
        .catch((error) => {
          errorFunction(error);
        });
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form autoComplete="off">
              <div className="loan-info  ">
                <div className=" m-0">
                  <TextField
                    type={"text"}
                    name="name"
                    value={formik.values.name}
                    label={"BusinessNature Name"}
                    required
                    formikRequired={formik?.errors?.name && formik?.touched?.name}
                    onChange={(e) => {
                      formik.setFieldValue("name", e.target.value);
                    }}
                  />
                </div>

                <div className="justify-content-end d-flex pt-2">
                  <button type="submit" className="btn create-button" title={"Save"} content={"Save"}>
                    {edit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateBusinessNature;
