import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import RightModal from "../../../Components/Modal/RightModal/RightModal";
import dashboardIcon from "../../../assets/dashboard-icon-1.png";
import { getDashboardDetails, getEventDetails } from "../Redux/thunk";
import "./dashboard.css";
const Dashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filters, setFilters] = useState({
    branch: null,
  });
  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning !!!";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Good Afternoon !!!";
    } else if (currentHour >= 17 && currentHour < 21) {
      return "Good Evening !!!";
    } else {
      return "Good Night !!!";
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const formattedDate = new Date(startDate).toLocaleDateString();
    const formattedendDate = new Date(endDate).toLocaleDateString();
    dispatch(getDashboardDetails({ startDate: formattedDate, endDate: formattedendDate }));
    // dispatch(getEventDetails({ startDate: formattedDate, endDate: formattedendDate }));
  }, [startDate, endDate]);
  const { eventDetails } = useSelector((state) => state.dashboard);
  const businessInspectionData = useSelector((state) => state.dashboard?.dashboardDetails?.businessInspectionData);
  const collateralInspectionData = useSelector((state) => state.dashboard?.dashboardDetails?.collateralInspectionData);
  const totalCollateralCount = useSelector((state) => state.dashboard?.dashboardDetails?.totalCollateralCount);
  const totalLeadCount = useSelector((state) => state.dashboard?.dashboardDetails?.totalLeadCount);
  const totalLoanCount = useSelector((state) => state.dashboard?.dashboardDetails?.totalLoanCount);
  let pieSeries = [];
  if (businessInspectionData) {
    pieSeries = [
      businessInspectionData?.totalPending,
      businessInspectionData?.totalApproved,
      businessInspectionData?.totalInspected,
    ];
  }
  let collateralInspectionSeries = [];
  if (collateralInspectionData) {
    collateralInspectionSeries = [
      collateralInspectionData?.totalPending,
      collateralInspectionData?.totalApproved,
      collateralInspectionData?.totalInspected,
    ];
  }

  const chartOptions = {
    chart: {
      type: "area",
      toolbar: { show: false },
      // stacked: false,
    },
    // stroke: {
    //   width: [0, 2, 5],
    //   curve: "smooth",
    // },

    fill: {
      opacity: [0.85],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
      },
    },
    // labels: ,
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {},
    yaxis: {
      show: false,
      title: {
        text: "Points",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points";
          }
          return y;
        },
      },
    },
    stroke: {
      width: 1,
      // width: 0, // Set the stroke width to 0 to remove the line
    },
    grid: {
      show: false, // Disables grid lines
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008EB0"],
  };

  const chartSeries = [
    {
      name: "TEAM A",
      type: "area",
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
    },
  ];

  const pieOptions = {
    chart: {
      type: "pie",
      class: "my-custom-chart",
    },
    legend: {
      show: false,
      position: "right",
      formatter: function (seriesName, opts) {
        return seriesName + ": " + opts.w.globals.series[opts.seriesIndex];
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        radius: 12,
      },
    },
    stroke: {
      width: 0, // This line removes the gap between the ring items
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Pending", "Approved", "Inspected"],
    colors: ["#008EB0", "rgba(96, 183, 206, 1)", "#EFF4FB"],
  };

  return (
    <>
      <div>
        <CommonPageHeader
          title={``}
          types={"dashboard"}
          filters={filters}
          setFilters={setFilters}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <div className="dashboard w-100" style={{ height: "80vh", overflow: "auto" }}>
          {/*top row cards*/}
          <div className="row  pb-2 pt-2 m-0" style={{ gap: "20px" }}>
            <div className="col row m-0 p-0" style={{ gap: "20px" }}>
              <div className="col-12 row m-0 p-0" style={{ gap: "20px" }}>
                <div className="card count-card h-100 col align-items-start justify-content-center">
                  <div className="d-flex justify-content-between" style={{ gap: "20px" }}>
                    <img src={dashboardIcon} alt="dashboard" style={{ height: "60px" }} className="card-icon" />
                    <div className="align-content-center card-detail">
                      <p className="m-0 ">
                        <span className="font-weight-bold">{totalLeadCount && totalLeadCount?.percentage}%</span> Since
                        Last Month
                      </p>
                      <h5 className="text-primary count">{totalLeadCount && totalLeadCount?.count}</h5>
                      <h6 className="title">Leads</h6>
                    </div>
                  </div>
                </div>
                <div className="card count-card h-100 col align-items-start justify-content-center">
                  <div className="d-flex justify-content-between" style={{ gap: "20px" }}>
                    <img src={dashboardIcon} alt="dashboard" style={{ height: "60px" }} className="card-icon" />
                    <div className="align-content-center card-detail">
                      <p className="m-0 ">
                        <span className="font-weight-bold">
                          {totalCollateralCount && totalCollateralCount.percentage}%
                        </span>{" "}
                        Since Last Month
                      </p>
                      <h5 className="text-primary count">{totalCollateralCount && totalCollateralCount.count}</h5>
                      <h6 className="title">Collateral</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 row m-0 p-0" style={{ gap: "20px" }}>
                <div className="card count-card h-100 col align-items-start justify-content-center">
                  <div className="d-flex justify-content-between" style={{ gap: "20px" }}>
                    <img src={dashboardIcon} alt="dashboard" style={{ height: "60px" }} className="card-icon" />
                    <div className="align-content-center card-detail">
                      <p className="m-0 ">
                        <span className="font-weight-bold">{totalLoanCount && totalLoanCount.percentage}%</span> Since
                        Last Month
                      </p>
                      <h5 className="text-primary count">{totalLoanCount && totalLoanCount.count}</h5>
                      <h6 className="title">Loan</h6>
                    </div>
                  </div>
                </div>
                <div className="card count-card h-100 col align-items-start justify-content-center">
                  <div className="d-flex justify-content-between" style={{ gap: "20px" }}>
                    <img src={dashboardIcon} alt="dashboard" style={{ height: "60px" }} className="card-icon" />
                    <div className="align-content-center card-detail">
                      <p className="m-0 ">
                        <span className="font-weight-bold">+23%</span> Since Last Month
                      </p>
                      <h5 className="text-primary count">2,490</h5>
                      <h6 className="title">Leads</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7 row m-0 p-0" style={{ gap: "20px" }}>
              <div className="col p-0">
                <div className="card  align-items-start p-3 h-100">
                  <div className="traffic w-100 align-items-center d-flex justify-content-between">
                    <div>
                      <h6 className="title w-100">Weekly Traffic</h6>
                      <h2>
                        2.579 <span className="title">Visitors</span>
                      </h2>
                    </div>
                    <p className="growth-loss">+2.5%</p>
                  </div>
                  <div id="chart" className="area-chart">
                    <ReactApexChart
                      className="hle"
                      options={chartOptions}
                      series={chartSeries}
                      type="area"
                      height={180}
                    />
                    <div id="html-dist"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-2 m-0" style={{ gap: "20px" }}>
            <div className="col-4 p-0">
              <div className="card p-3">
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="m-0 ">Inspection Status</h5>
                  <div></div>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th className="text-left p-0">Name</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left p-0">John Doe</td>
                      <td>Approved</td>
                      <td>2022-01-01</td>
                      <td>2022-01-01</td>
                    </tr>
                    <tr>
                      <td className="text-left p-0">John Doe</td>
                      <td>Approved</td>
                      <td>2022-01-01</td>
                      <td>2022-01-01</td>
                    </tr>
                    <tr>
                      <td className="text-left p-0">John Doe</td>
                      <td>Approved</td>
                      <td>2022-01-02</td>
                      <td>2022-01-02</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col p-0">
              <div className=" card flex-grow-1 h-100">
                <div className="w-100 h-100 p-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="m-0 ">Business Inspection Status</h5>
                  </div>
                  <div className="d-flex align-items-center  h-100" style={{ gap: "0px" }}>
                    <div id="chart" className="flex-grow-1 mt-3">
                      {pieOptions && pieSeries && (
                        <ReactApexChart
                          options={pieOptions ? pieOptions : {}}
                          series={pieSeries ? pieSeries : []}
                          type="pie"
                          width={130}
                          height={130}
                        />
                      )}
                    </div>
                    <div
                      className="bg-white rounded flex-grow-1"
                      style={{ boxShadow: "0px 18px 40px 0px rgba(112, 144, 176, 0.12)", padding: "20px" }}
                    >
                      <div className="row" style={{ rowGap: "20px" }}>
                        <div className="pie-count col-6 border-right align-content-end text-right">
                          <div
                            className="count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "#008EB0" }}></span>
                            Total
                          </div>
                          <h4>
                            {businessInspectionData &&
                              businessInspectionData.totalPending +
                                businessInspectionData.totalApproved +
                                businessInspectionData.totalInspected}
                          </h4>
                        </div>
                        <div className="pie-count col-6  align-content-end text-right">
                          <div
                            className=" count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "#008EB0" }}></span>
                            Pending
                          </div>
                          <h4>{businessInspectionData && businessInspectionData.totalPending}</h4>
                        </div>{" "}
                        <div className="pie-count col-6 border-right align-content-end text-right">
                          <div
                            className=" count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "#EFF4FB" }}></span>
                            Inspected
                          </div>
                          <h4>{businessInspectionData && businessInspectionData.totalInspected}</h4>
                        </div>{" "}
                        <div className="pie-count col-6  align-content-end text-right">
                          <div
                            className=" count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "rgba(96, 183, 206, 1)" }}></span>
                            Approved
                          </div>
                          <h4>{businessInspectionData && businessInspectionData.totalApproved}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="html-dist"></div>
                </div>
              </div>
            </div>

            <div className="col p-0">
              <div className=" card flex-grow-1 h-100">
                <div className="w-100 h-100 p-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="m-0 ">Collateral Inspection Status</h5>
                  </div>
                  <div className="d-flex align-items-center  h-100" style={{ gap: "0px" }}>
                    <div id="chart" className="flex-grow-1 mt-3">
                      <ReactApexChart
                        options={pieOptions}
                        series={collateralInspectionSeries}
                        type="pie"
                        width={130}
                        height={130}
                      />
                    </div>
                    <div
                      className="bg-white rounded flex-grow-1"
                      style={{ boxShadow: "0px 18px 40px 0px rgba(112, 144, 176, 0.12)", padding: "20px" }}
                    >
                      <div className="row" style={{ rowGap: "20px" }}>
                        <div className="pie-count col-6 border-right align-content-end text-right">
                          <div
                            className="count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "#008EB0" }}></span>
                            Total
                          </div>
                          <h4>
                            {collateralInspectionData &&
                              collateralInspectionData.totalPending +
                                collateralInspectionData.totalApproved +
                                collateralInspectionData.totalInspected}
                          </h4>
                        </div>
                        <div className="pie-count col-6  align-content-end text-right">
                          <div
                            className=" count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "#008EB0" }}></span>
                            Pending
                          </div>
                          <h4>{collateralInspectionData && collateralInspectionData.totalPending}</h4>
                        </div>{" "}
                        <div className="pie-count col-6 border-right align-content-end text-right">
                          <div
                            className=" count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "#EFF4FB" }}></span>
                            Inspected
                          </div>
                          <h4>{collateralInspectionData && collateralInspectionData.totalInspected}</h4>
                        </div>{" "}
                        <div className="pie-count col-6  align-content-end text-right">
                          <div
                            className=" count-text d-flex align-items-center justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            <span style={{ backgroundColor: "rgba(96, 183, 206, 1)" }}></span>
                            Approved
                          </div>
                          <h4>{collateralInspectionData && collateralInspectionData.totalApproved}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
