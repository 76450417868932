import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllBusinessParagraphQuestion = createAsyncThunk(
  "business-question/getAllBusinessQuestion",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllBusinessParagraphQuestion(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBusinessQuestion = createAsyncThunk(
  "business-question/getBusinessQuestion",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getBusinessQuestion(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const createBusinessQuestion = createAsyncThunk(
//   "business-question/createBusinessQuestion",
//   async (values, { rejectWithValue }) => {
//     try {
//       const { data } = await API.createBusinessQuestion(values);
//       return data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );
export const createBusinessQuestion = createAsyncThunk(
  "business-question/createBusinessQuestion",
  async (data, { rejectWithValue }) => {
    const { loan, quater, dateOfInspection } = data;

    try {
      const body = JSON.stringify({
        loan,
        quater,
        dateOfInspection,
      });
      const { data } = await API.createBusinessQuestion(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create Inspection");
    }
  }
);
export const updateBusinessQuestion = createAsyncThunk(
  "business-question/updateBusinessQuestion",
  async (data, { rejectWithValue }) => {
    const { id, loan, quater, dateOfInspection } = data;

    try {
      const body = JSON.stringify({
        id,
        loan,
        quater,
        dateOfInspection,
      });
      const { data } = await API.updateBusinessQuestion(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update Inspection");
    }
  }
);

export const getBooleanQuestionnaire = createAsyncThunk(
  "boolean-questionnaire/getBooleanQuestionnaire",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getBooleanQuestionnaire();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBusinessQuestionAnswer = createAsyncThunk(
  "business-question/createBusinessQuestionAnswer",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.createBusinessQuestionAnswer(values);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getParagraphBusinessQuestionAnswer = createAsyncThunk(
  "business-question/getParagraphBusinessQuestionAnswer",
  async (inspectionId, { rejectWithValue }) => {
    try {
      const { data } = await API.getParagraphBusinessQuestionAnswer(inspectionId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBooleanBusinessQuestionAnswer = createAsyncThunk(
  "business-question/getBooleanBusinessQuestionAnswer",
  async (inspectionId, { rejectWithValue }) => {
    try {
      const { data } = await API.getBooleanBusinessQuestionAnswer(inspectionId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTableBusinessQuestionAnswer = createAsyncThunk(
  "business-question/getTableBusinessQuestionAnswer",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await API.getTableBusinessQuestionAnswer(values);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBusinessTableQuestionAnswer = createAsyncThunk(
  "business-question/updateBusinessTableQuestionAnswer",
  async (values, { rejectWithValue }) => {
    const { id, formData } = values;
    try {
      const { data } = await API.updateBusinessTableQuestionAnswer(id, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInspectionPdfReoprt = createAsyncThunk(
  "inspection-pdf/getInspectionPdfReoprt",
  async (inspectionId, { rejectWithValue }) => {
    try {
      const { data } = await API.getInspectionPdfReoprt(inspectionId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
