import React, { useRef } from "react";
import { useSelector } from "react-redux";
import ColumnResize from "react-table-column-resizer";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import Status from "../../../Components/Status";
import { PERMISSIONS } from "../../../Utils/permission";
import { userGroupEditSuccess } from "../Redux/userGroupSlice";

const UserGroup = ({ dispatch, setPostsPerPage, setShowUserGroupModal }) => {
  const listInnerRef = useRef(null);
  const next = useSelector((state) => state.userGroup.next);
  const userGroups = useSelector((state) => state.userGroup.userGroups);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const updateUserGroupPermission = isSuperuser || permissions.includes(PERMISSIONS.userGroup.updateCustomGroup);

  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 1) {
      if (next) {
        scrollToEnd();
      }
    }
  };

  const handleEdit = (id) => {
    dispatch(userGroupEditSuccess(id));
    setShowUserGroupModal(true);
  };

  return (
    <div className="flex-grow-1 w-100 p-0 m-0 d-flex" style={{ overflow: "hidden" }}>
      <div className="table-scrollable w-100 p-0 mt-2" onScroll={handleScroll} ref={listInnerRef}>
        <table className="">
          <thead>
            <tr>
              <th>SN</th>
              <ColumnResize id={1} className="columnResizer" />
              <th>User Group</th>
              <ColumnResize id={2} className="columnResizer" minWidth={120} />
              <th>Status</th>
              <ColumnResize id={5} className="columnResizer" minWidth={120} />
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userGroups?.map((userGroup, i) => {
              const { id, name, isActive } = userGroup;

              return (
                <tr key={id} onDoubleClick={() => handleEdit(id)} style={{ cursor: "pointer" }}>
                  <td>{i + 1}</td>
                  <td className="column_resizer_body" />
                  <td>{name ? name : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>
                    <Status active={isActive} />
                  </td>
                  <td className="column_resizer_body" />
                  <td>{updateUserGroupPermission && <DetailActionButton type={"edit"} onClick={() => handleEdit(id)} />}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserGroup;
