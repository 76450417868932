import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import useDebounce from "../../../Utils/Hooks/useDebounce";

import { setCollapsed, setShowModal } from "../../../Redux/Layout/layoutSlice";
// import "../../Leads/Pages/lead.css";
import SystemActivityListing from "./SystemActivityListing";
import { getSystemActivities } from "../Redux/thunk";
const title = "System Activities";
const types = "system-activity";

const SystemActivity = () => {
  const dispatch = useDispatch();
  const [postsPerPage, setPostsPerPage] = useState(20);
  const { showModal } = useSelector((state) => state.layout);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(null);
  const [loanType, setLoanType] = useState(null);
  const [collateralType, setCollateralType] = useState(null);
  const [inspectionType, setInspectionType] = useState(null);
  const [cif, setCif] = useState("");
  const [showLoanModal, setShowLoanModal] = useState(false);
  const { count, activities, loading } = useSelector((state) => state.systemActivity);

  const debouncedSearch = useDebounce(search, 500);
  const debouncedCif = useDebounce(cif, 500);
  useEffect(() => {
    if (search === "") {
      let value = {
        status,
        loanType,
        postsPerPage,
        collateralType,
        inspectionType,
      };
      dispatch(getSystemActivities(value));
    } else {
      let value = {
        search: debouncedSearch,
        status,
        postsPerPage,
        loanType,
        collateralType,
        inspectionType,
      };
      //   dispatch(handleSearch(value));
    }
  }, [dispatch, postsPerPage, debouncedSearch, status, loanType, collateralType, inspectionType]);

  return (
    <div className="lead-wrapper d-flex flex-column " style={{ height: "100%" }}>
      <CommonPageHeader
        title={title}
        types={types}
        search={search}
        loanType={loanType}
        status={status}
        setStatus={setStatus}
        setLoanType={setLoanType}
        setSearch={setSearch}
        count={count}
        data={activities}
        loading={loading}
        collateralType={collateralType}
        setCollateralType={setCollateralType}
        inspectionType={inspectionType}
        setInspectionType={setInspectionType}
      />

      <SystemActivityListing
        dispatch={dispatch}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        setShowModal={setShowModal}
        collapsed={setCollapsed}
        showLoanModal={showLoanModal}
        setShowLoanModal={setShowLoanModal}
      />

      {/* {showLoanModal && (
        <Modal
          dispatch={setShowModal}
          showModal={showLoanModal}
          setShowModal={setShowLoanModal}
          header={edit ? "Update Client Registration" : "Add Client Registration"}
          types={types}
          size={"modal-lg"}
        >
          <CreateLoan
            dispatch={dispatch}
            showModal={showLoanModal}
            setShowModal={setShowLoanModal}
            postsPerPage={postsPerPage}
          />
        </Modal>
      )} */}
    </div>
  );
};

export default SystemActivity;
