import axiosInstance from "../../../../../Utils/axios";
export const getLoanInfo = (id) => axiosInstance.get(`api/v1/lead-app/loan-info?id=${id}`);

export const getAllLoanfo = (postsPerPage, status) =>
  axiosInstance.get(
    `api/v1/lead-app/loan-info?offset=0&limit=${postsPerPage}&ordering=-id&lead_LoanType=${
      status !== null ? status?.id : ""
    }`
  );

export const createLoanInfo = (body) => axiosInstance.post(`api/v1/lead-app/save-loan`, body);

export const updateLoanInfo = (id, body) => axiosInstance.patch(`api/v1/lead-app/update-loan/${id}`, body);

export const getGuarantor = (id) => axiosInstance.get(`api/v1/lead-app/guarantor/${id}`);

export const createGuarantor = (body) => axiosInstance.post(`api/v1/lead-app/guarantor`, body);

export const updateGuarantor = (id, body) => axiosInstance.patch(`api/v1/lead-app/guarantor/${id}`, body);

export const getSpecificLoanInfoGuarantor = (id, postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/guarantor?loanInfo=${id}&offset=0&limit=${postsPerPage}&ordering=-id`);

export const getSpecificGuarantor = (id) => axiosInstance.get(`api/v1/lead-app/guarantor/${id}`);

export const getBSIR = (loanInfo) => axiosInstance.get(`api/v1/lead-app/bsir?loanInfo=${loanInfo}`);

export const getSpecificBSIR = (id) => axiosInstance.get(`api/v1/lead-app/bsir/${id}`);

export const createBSIR = (body) => axiosInstance.post(`api/v1/lead-app/bsir`, body);

export const updateBSIR = (id, body) => axiosInstance.patch(`api/v1/lead-app/bsir/${id}`, body);

export const createSisterConcerns = (body) => axiosInstance.post(`api/v1/lead-app/sister-concerns`, body);

export const updateSisterConcerns = (id, body) => axiosInstance.patch(`api/v1/lead-app/sister-concerns/${id}`, body);

export const getSpecificLoanInfoSisterConcerns = (id, postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/sister-concerns?loanInfo=${id}&offset=0&limit=${postsPerPage}&ordering=-id`);
export const getSpecificSisterConcerns = (id) => axiosInstance.get(`api/v1/lead-app/sister-concerns/${id}`);
export const getBusinessNature = (id) => axiosInstance.get(`api/v1/lead-app/business-nature`);
export const createBusinessNature = (body) => axiosInstance.post(`api/v1/lead-app/business-nature`, body);
// -----------------------
export const createBankRelation = (body) => axiosInstance.post(`api/v1/lead-app/bank-relations`, body);
export const updateBankRelation = (id, body) => axiosInstance.patch(`api/v1/lead-app/bank-relations/${id}`, body);
export const getSpecificLoanInfoBankRelation = (id, postsPerPage) =>
  axiosInstance.get(
    `api/v1/lead-app/bank-relations?facilityType_LoanInfo=${id}&offset=0&limit=${postsPerPage}&ordering=-id`
  );
export const getSpecificBankRelation = (id) => axiosInstance.get(`api/v1/lead-app/bank-relations/${id}`);
// -----------------------

export const createShareholder = (body) => axiosInstance.post(`api/v1/lead-app/share-holder-details`, body);
export const updateShareholder = (id, body) => axiosInstance.patch(`api/v1/lead-app/share-holder-details/${id}`, body);
export const getSpecificLoanInfoShareholder = (id, postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/share-holder-details?loanInfo=${id}&offset=0&limit=${postsPerPage}&ordering=-id`);
export const getSpecificShareholder = (id) => axiosInstance.get(`api/v1/lead-app/share-holder-details/${id}`);

export const createInspection = (body) => axiosInstance.post(`api/v1/loan-inspection-app/new-inspection`, body);
export const updateInspection = (id, body) =>
  axiosInstance.patch(`api/v1/loan-inspection-app/new-inspection/${id}`, body);

export const getSpecificCollateralInspection = (id) =>
  axiosInstance.get(`api/v1/loan-inspection-app/new-inspection?collateral=${id}`);
export const getSpecificLeadInspection = (id) =>
  axiosInstance.get(`api/v1/loan-inspection-app/new-inspection?collateral_LoanInfo=${id}`);
export const getSpecificInspection = (id) => axiosInstance.get(`api/v1/loan-inspection-app/new-inspection/${id}`);

export const createFacility = (body) => axiosInstance.post(`api/v1/lead-app/facility`, body);

export const updateFacility = (id, body) => axiosInstance.patch(`api/v1/lead-app/facility/${id}`, body);

export const getSpecificFacility = (id, postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/facility?loanInfo=${id}&offset=0&limit=${postsPerPage}&ordering=-id`);

// APIs for collateral type

export const getCollateralType = () => axiosInstance.get(`api/v1/lead-app/collateral-type`);

export const createCollateralType = (body) => axiosInstance.post(`api/v1/lead-app/collateral_type`, body);

export const updateCollateralType = (id, body) => axiosInstance.patch(`api/v1/lead-app/collateral_type/${id}`, body);

export const getSpecificCollateralType = (id) => axiosInstance.get(`api/v1/lead-app/collateral_type/${id}`);

//APIs for collateral

export const createCollateral = (body) => axiosInstance.post(`api/v1/lead-app/collateral`, body);

export const updateCollateral = (id, body) => axiosInstance.patch(`api/v1/lead-app/collateral/${id}`, body);

export const getSpecificLoanInfoCollateral = (id, postsPerPage) => {
  return axiosInstance.get(`api/v1/lead-app/collateral?loanInfo=${id}&offset=0&limit=${postsPerPage}&ordering=-id`);
};

export const getSpecificCollateral = (id) => {
  return axiosInstance.get(`api/v1/lead-app/collateral/${id}`);
};

export const getAllCollateral = (postsPerPage) => {
  return axiosInstance.get(`api/v1/lead-app/collateral?offset=0&limit=${postsPerPage}&ordering=-id`);
};

export const handleSearch = (search, postsPerPage, loanType, status) =>
  axiosInstance.get(`api/v1/lead-app/loan-info?search=${search}&limit=${postsPerPage}&offset=0&ordering=-id`);
