import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import LodingListingSkeleton from "../../../Components/Skeleton/LodingListingSkeleton";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";
import useDebounce from "../../../Utils/Hooks/useDebounce";
import { PERMISSIONS } from "../../../Utils/permission";
import { getUser, handleSearch } from "../Redux/thunk";
import { clearEditUser } from "../Redux/userSlice";
import CreateUser from "./CreateUser";
import User from "./User";
import "./user.css";

const Modal = lazy(() => import("../../../Components/Modal/Modal"));

const title = "User";
const types = "user";

const UserListing = () => {
  const dispatch = useDispatch();
  const loadingUser = useSelector((state) => state.user.loadingUser);
  const users = useSelector((state) => state.user.users);
  const count = useSelector((state) => state.user.count);
  const edit = useSelector((state) => state.user.edit);
  const { showModal } = useSelector((state) => state.layout);
  const [showUserModal, setShowUserModal] = useState(false);
  const [search, setSearch] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(20);
  const debouncedSearch = useDebounce(search, 500);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const updateUserPermission = isSuperuser || permissions.includes(PERMISSIONS.user.updateUser);

  useEffect(() => {
    if (search === "") {
      dispatch(getUser(postsPerPage));
    } else {
      dispatch(handleSearch({ search, postsPerPage }));
    }
    // eslint-disable-next-line
  }, [postsPerPage, debouncedSearch]);

  return (
    <>
      <div className="lead-wrapper d-flex flex-column " style={{ height: "100%" }}>
        <CommonPageHeader title={title} dispatch={dispatch} showModal={showModal} search={search} setSearch={setSearch} loading={loadingUser} data={users} count={count} />

        {loadingUser && <LodingListingSkeleton />}
        {!loadingUser && <User dispatch={dispatch} setShowUserModal={setShowUserModal} setPostsPerPage={setPostsPerPage} />}

        {/* THIS IS INTENTIONALLY COMMENTED BECAUSE USER IS BEING LOADED FROM CBS */}
        {/* <CommonCreateButton types={types} showModal={showUserModal} setShowModal={setShowUserModal}  /> */}
      </div>
      {updateUserPermission && showUserModal && (
        <Suspense fallback={<div></div>}>
          <Modal
            dispatch={setShowModal}
            showModal={showUserModal}
            setShowModal={setShowUserModal}
            header={edit ? "Update User" : "Add User"}
            types={types}
            edit={edit}
            size={"modal-xl"}
            clearAction={clearEditUser}
          >
            <CreateUser dispatch={dispatch} setShowModal={setShowUserModal} postsPerPage={postsPerPage} />
          </Modal>
        </Suspense>
      )}
    </>
  );
};

export default UserListing;
