import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getBusinessNature,
  getAllBusinessNature,
  getNext,
  getPrevious,
  createBusinessNature,
  handleSearch,
  getSpecificBusinessNature,
} from "./thunk";

const initialState = {
  businessNatures: [],
  edit: false,
  businessNature: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingBusinessNature: false,
};

export const businessNatureSlice = createSlice({
  name: "businessNature",
  initialState,
  reducers: {
    editBusinessNature: (state) => {
      state.edit = true;
    },
    clearEditBusinessNature: (state) => {
      state.edit = false;
      state.businessNature = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBusinessNature.pending, (state) => {
      state.loadingBusinessNature = true;
    });
    builder.addCase(getBusinessNature.fulfilled, (state, action) => {
      state.loadingBusinessNature = false;
      state.edit = false;
      state.businessNatures = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getBusinessNature.rejected, (state) => {
      state.loadingBusinessNature = false;
      state.edit = false;
    });
    builder.addCase(createBusinessNature.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBusinessNature.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createBusinessNature.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(getSpecificBusinessNature.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificBusinessNature.fulfilled, (state, action) => {
      state.businessNature = action.payload;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificBusinessNature.rejected, (state) => {
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(
        getAllBusinessNature.pending,
        getPrevious.pending,
        getNext.pending,

        handleSearch.pending
      ),
      (state) => {
        state.loadingBusinessNature = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllBusinessNature.fulfilled,
        getPrevious.fulfilled,
        getNext.fulfilled,

        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loadingBusinessNature = false;
        state.businessNatures = action.payload.results;
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllBusinessNature.rejected,
        getPrevious.rejected,
        getNext.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loadingBusinessNature = false;
      }
    );
  },
});
export const { editBusinessNature, clearEditBusinessNature } = businessNatureSlice.actions;

export default businessNatureSlice.reducer;
