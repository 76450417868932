import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";
import CreateFacilities from "./CreateFacilities";
import Modal from "../../../Components/Modal/Modal";
import Facilities from ".";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import { editFacility } from "../Redux/facilitiesSlice";
import { deleteFacility, getFacility, getSpecificFacility } from "../Redux/thunk";
import { errorFunction, successFunction } from "../../../Components/Alert/Alert";
import Nodata from "../../Loan/Pages/NoData/Nodata";

const FacilitiesListing = ({ masterData }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const { facilities, edit, facility } = useSelector((state) => state?.facility);

  return (
    <>
      <div>
        {facilities?.length > 0 ? (
          <div className="loan-info py-2  mt-2">
            <div className="table-scrollable">
              <table className="">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Facility</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {facilities?.map((fac, index) => {
                    const { name, id } = fac;
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        <td>
                          <DetailActionButton
                            type={"edit"}
                            onClick={async () => {
                              dispatch(editFacility());
                              dispatch(getSpecificFacility(id));
                              setCreateModal(true);
                            }}
                          />
                        </td>
                        <td>
                          <DetailActionButton
                            type={"delete"}
                            onClick={async () => {
                              dispatch(deleteFacility(id))
                                .unwrap()
                                .then(() => {
                                  successFunction("Facility deleted Successfully.");
                                  dispatch(getFacility(postsPerPage));
                                })
                                .catch((error) => {
                                  errorFunction(error);
                                });
                    
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
         <Nodata title={"Facilities"} onClick={() => setCreateModal(true)} />
        )}
      </div>
      {createModal && (
        <Modal
          size={"modal-lg"}
          showModal={createModal}
          setShowModal={setCreateModal}
          header={edit ? "Update Facilities" : "Facilities"}
          dispatch={setShowModal}
        >
          <CreateFacilities
            masterData={masterData}
            showModal={createModal}
            setShowModal={setCreateModal}
            postsPerPage={postsPerPage}
          />
        </Modal>
      )}
    </>
  );
};

export default FacilitiesListing;
