import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLoans = createAsyncThunk("loans/getLoans", async (value, { rejectWithValue }) => {
  const { postsPerPage, loanType, status } = value;
  try {
    const { data } = await API.getLoans(postsPerPage, loanType, status);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// thunks for loan

export const getAllLoan = createAsyncThunk("loans/getAllLoan", async (value, { rejectWithValue }) => {
  const { postsPerPage, status, loanType } = value;
  try {
    const { data } = await API.getAllLoan(postsPerPage, status, loanType);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getLoan = createAsyncThunk("loans/getLoan", async (value, { rejectWithValue }) => {
  const id = value;
  try {
    const { data } = await API.getLoan(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLoan = createAsyncThunk("loans/createLoan", async (values, { rejectWithValue }) => {
  try {
    const { data } = await API.createLoan(values);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLoan = createAsyncThunk("loans/updateLoan", async (data, { rejectWithValue }) => {
  const { id, values } = data;

  try {
    const { data } = await API.updateLoan(id, values);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const handleSearch = createAsyncThunk("loans/handleSearch", async (value, { rejectWithValue }) => {
  const { search, postsPerPage, loanType, status } = value;
  try {
    const { data } = await API.handleSearch(search, postsPerPage, loanType, status);
    return data;
  } catch (error) {
    rejectWithValue("Failed to get Leads");
  }
});

// thunks for history

export const getCollateralHistory = createAsyncThunk(
  "loans/getCollateralHistory",
  async (value, { rejectWithValue }) => {
    const id = value;
    try {
      const { data } = await API.getCollateralHistory(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInspectionHistory = createAsyncThunk(
  "loans/getInspectionHistory",
  async (value, { rejectWithValue }) => {
    const id = value;
    try {
      const { data } = await API.getInspectionHistory(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllCollateralType = createAsyncThunk(
  "loans/getAllCollateralType",
  async (value, { rejectWithValue }) => {
    const { postsPerPage } = value;
    try {
      const { data } = await API.getAllCollateralType(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSpecificLoanInfo = createAsyncThunk("loans/getSpecificLoanInfo", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getSpecificLoanInfo(id);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Specific Loan Info");
  }
});


// NEW APIS 


export const getCIFDetails = createAsyncThunk(
  "loans/getCIFDetails",
  async (cif, { rejectWithValue }) => {
    
    try {
      const { data } = await API.getCIFDetails(cif);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);