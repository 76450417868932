import React, { lazy, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ColumnResize from "react-table-column-resizer";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import Status from "../../../Components/Status";
import { PERMISSIONS } from "../../../Utils/permission";
import { organizationEditSuccess } from "../Redux/organizationSlice";

const DocumentViewModal = lazy(() => import("../../../Components/Modal/DocumentViewModal"));

const Organization = ({ setPostsPerPage, setShowUserSetupModal }) => {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const { next, organizations } = useSelector((state) => state.organization);
  const [viewDocument, setViewDocument] = useState(false);
  const [documentToView, setDocumentToView] = useState(null);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const updateOrganizationPermission = isSuperuser || permissions.includes(PERMISSIONS.organizationSetup.updateOrganizationSetup);

  const handleScroll = (event) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + offsetHeight === scrollHeight && next) {
      setPostsPerPage((prev) => prev + 10);
    }
  };

  const handleEdit = (id) => {
    dispatch(organizationEditSuccess(id));
    setShowUserSetupModal(true);
  };

  const handleView = (document) => {
    setDocumentToView(document);
    setViewDocument(true);
  };

  const renderViewButton = (document) =>
    document ? (
      <button type="button" className="btn btn-sm btn-secondary" onClick={() => handleView(document)}>
        View
      </button>
    ) : (
      "N/A"
    );

  const tableHeaders = ["SN", "Name", "Phone No", "Email", "Address", "Logo", "Stamp", "Signature", "Active", ""];

  return (
    <>
      <div className="row">
        <div className="col-12 table-scrollable mt-2" onScroll={handleScroll} ref={listRef}>
          <table className="listing-table">
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <React.Fragment key={index}>
                    <th>{header}</th>
                    <ColumnResize id={index + 1} className="columnResizer" minWidth={120} />
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {organizations?.map((org, i) => {
                const { id, name, phoneNo, email, address, logo, stamp, signature, active } = org;
                return (
                  <tr key={id} onDoubleClick={() => handleEdit(id)} style={{ cursor: "pointer" }}>
                    <td>{i + 1}</td>
                    <td className="column_resizer_body" />
                    <td>{name || "N/A"}</td>
                    <td className="column_resizer_body" />
                    <td>{phoneNo || "N/A"}</td>
                    <td className="column_resizer_body" />
                    <td>{email || "N/A"}</td>
                    <td className="column_resizer_body" />
                    <td>{address || "N/A"}</td>
                    <td className="column_resizer_body" />
                    <td>{renderViewButton(logo)}</td>
                    <td className="column_resizer_body" />
                    <td>{renderViewButton(stamp)}</td>
                    <td className="column_resizer_body" />
                    <td>{renderViewButton(signature)}</td>
                    <td className="column_resizer_body" />
                    <td>
                      <Status active={active} />
                    </td>
                    <td className="column_resizer_body" />
                    <td>{updateOrganizationPermission && <DetailActionButton type="edit" onClick={() => handleEdit(id)} />}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {viewDocument && <DocumentViewModal documentToView={documentToView} showModal={viewDocument} setShowModal={setViewDocument} size="modal-md" />}
    </>
  );
};

export default Organization;
