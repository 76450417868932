import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getFacility,
  getAllFacility,
  getNext,
  getPrevious,
  createFacility,
  handleSearch,
  getSpecificFacility,
} from "./thunk";

const initialState = {
  facilities: [],
  edit: false,
  facility: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingFacility: false,
};

export const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    editFacility: (state) => {
      state.edit = true;
    },
    clearEditFacility: (state) => {
      state.edit = false;
      state.facility = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getFacility.pending, (state) => {
      state.loadingFacility = true;
    });
    builder.addCase(getFacility.fulfilled, (state, action) => {
      state.loadingFacility = false;
      state.edit = false;
      state.facilities = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getFacility.rejected, (state) => {
      state.loadingFacility = false;
      state.edit = false;
    });
    builder.addCase(createFacility.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createFacility.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createFacility.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(getSpecificFacility.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificFacility.fulfilled, (state, action) => {
      state.facility = action.payload;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificFacility.rejected, (state) => {
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(
        getAllFacility.pending,
        getPrevious.pending,
        getNext.pending,

        handleSearch.pending
      ),
      (state) => {
        state.loadingFacility = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllFacility.fulfilled,
        getPrevious.fulfilled,
        getNext.fulfilled,

        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loadingFacility = false;
        state.facilities = action.payload.results;
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllFacility.rejected,
        getPrevious.rejected,
        getNext.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loadingFacility = false;
      }
    );
  },
});
export const { editFacility, clearEditFacility } = facilitySlice.actions;

export default facilitySlice.reducer;
