import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllBusinessInspection = createAsyncThunk(
  "business-inspection/getAllBusinessInspection",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllBusinessInspection(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBusinessInspection = createAsyncThunk(
  "business-inspection/getBusinessInspection",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getBusinessInspection(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBusinessInspection = createAsyncThunk(
  "business-inspection/createBusinessInspection",
  async (data, { rejectWithValue }) => {
    const { loan, quater, dateOfInspection, natureOfInspection } = data;

    try {
      const body = JSON.stringify({
        loan,
        quater,
        dateOfInspection,
        natureOfInspection,
      });
      const { data } = await API.createBusinessInspection(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create Inspection");
    }
  }
);

export const updateBusinessInspection = createAsyncThunk(
  "business-inspection/updateBusinessInspection",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    try {
      const { data } = await API.updateBusinessInspection(id, values);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
