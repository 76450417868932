import axiosInstance from "../../../Utils/axios";

// obtaining the paginated data
export const getCollateralType = (postsPerPage) => axiosInstance.get(`api/v1/lead-app/collateral_type?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all data
export const getAllCollateralType = () => axiosInstance.get(`api/v1/lead-app/collateral_type?ordering=-id`);

export const getSpecificCollateralType = (id) => axiosInstance.get(`api/v1/lead-app/collateral_type/${id}`);
//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//creating function

export const createCollateralType = (body) => axiosInstance.post(`api/v1/lead-app/collateral_type`, body);
export const updateCollateralType = (id, body) => axiosInstance.patch(`api/v1/lead-app/collateral_type/${id}`, body);
export const deleteCollateralType = (id) => axiosInstance.delete(`api/v1/lead-app/collateral_type/${id}`, id);
//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/collateral_type?offset=0&limit=${postsPerPage}&search=${search}`);
