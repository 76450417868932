import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

export const getDashboardDetails = createAsyncThunk(
  "dashboard/getDashboardDetails",
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const { data } = await API.getDashboardDetails({ startDate, endDate });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEventDetails = createAsyncThunk(
  "dashboard/getEventDetails",
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const { data } = await API.getEventDetails({ startDate, endDate });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
