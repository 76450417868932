import axios from "axios";
import { errorFunction } from "../Components/Alert/Alert";
import { authError, tokenRefreshSuccess } from "../Redux/Auth/authSlice";
import { store } from "../Store/store";
import getCookie from "./Cookies/getCookie";
import deleteCookie from "./Cookies/deleteCookie";
import setCookie from "./Cookies/setCookie";
import { generateSignature, MESSAGE } from "./getSignatureKey";

// Base URL for all API requests
const BASE_URL = process.env.REACT_APP_BASE_URL;

// Default headers that will be sent with every request
const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

// Standard metadata that needs to be included with every request
const APP_METADATA = {
  appType: 1, // Identifies the application type (e.g., web = 1)
  deviceType: 2, // Identifies the device type (e.g., desktop = 2)
  message: MESSAGE, // Custom message for API validation
  signature: generateSignature(), // Security signature for API validation
};

// Map of error status codes to user-friendly error messages
const ERROR_MESSAGES = {
  network: "Internal Server Error. Contact IT manager !!!",
  500: "Internal Server Error. Contact IT manager !!!",
  403: "Permission Denied. Contact IT manager !!!",
  404: "Page Not Found !!!!!",
};

// ===== CREATE AXIOS INSTANCE =====

// Create a custom axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: DEFAULT_HEADERS,
  ...APP_METADATA,
});

export const publicAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: DEFAULT_HEADERS,
  appType: 1,
  deviceType: 2,
});

// ===== TOKEN REFRESH MANAGEMENT =====

// race condition login
let isRefreshing = false;
let subscribers = [];

function onTokenRefreshed(newToken) {
  subscribers.forEach((callback) => callback(newToken));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

const abortController = new AbortController();
// ===== HELPER FUNCTIONS =====

const appendMetadata = (data) => {
  // Handle FormData (usually used for file uploads)
  if (data instanceof FormData) {
    Object.entries(APP_METADATA).forEach(([key, value]) => {
      data.append(key, value);
    });
    return data;
  }

  // Handle string data by parsing it first
  const parsedData = typeof data === "string" ? JSON.parse(data) : data;
  return { ...parsedData, ...APP_METADATA };
};

// ===== REQUEST INTERCEPTOR =====

/**
 * Modifies requests before they are sent
 * - Checks internet connection
 * - Adds authentication token
 * - Adds required metadata
 */
axiosInstance.interceptors.request.use(
  (config) => {
    // Check if user is online
    if (!window.navigator.onLine) {
      errorFunction("No Internet Connection !!!");
      return Promise.reject(new Error("No Internet Connection"));
    }

    // Add authentication token and required headers
    const accessToken = getCookie("accessToken");
    config.headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : "", // Add token if it exists
      Message: MESSAGE,
      Signature: generateSignature(),
    };

    config.baseURL = BASE_URL;

    // Add metadata to request data if it exists
    if (config.data !== undefined) {
      // if config.data is array send as array
      if (Array.isArray(config.data)) {
        return config;
      }
      config.data = appendMetadata(config.data);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// ===== RESPONSE INTERCEPTOR =====

/**
 * Handles responses before they reach your application code
 * - Manages error responses
 * - Handles token refresh when authorization fails
 * - Queues requests while token is being refreshed
 */
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  async (error) => {
    const originalRequest = error.config;

    //when refresh token is also not valid
    if (error.response.status === 401 && originalRequest.url === `api/v1/user-app/generate-access-token`) {
      store.dispatch(authError());
      return errorFunction(`Refresh Token Expired. Please Login.`);
    }

    //accessing new access token from refresh token
    else if (error.response?.data.code === "token_not_valid" && !originalRequest._retry) {
      //call for refresh token
      originalRequest._retry = true;
      // Check if another refresh token request is in progress
      if (isRefreshing) {
        // Add to subscribers and wait for the new token
        return new Promise((resolve) => {
          addSubscriber((newToken) => {
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      isRefreshing = true;

      try {
        // Call for refresh token
        const body = JSON.stringify({ refresh: getCookie("refreshToken") });
        deleteCookie("accessToken");
        const response = await axiosInstance.post(`api/v1/user-app/generate-access-token`, body);

        if (response.status === 200) {
          const newAccessToken = response.data.access;
          setCookie("accessToken", newAccessToken);

          // Notify all subscribers about the new token
          onTokenRefreshed(newAccessToken);

          // Update the original request with the new token
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        store.dispatch(authError());
        errorFunction(`Session expired. Please login again.`);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    } //server down
    else if (error.message === "Network Error") {
      errorFunction("Internal Server Error. Contact IT manager !!!");
    } else if (error.response?.status === 500) {
      errorFunction("Internal Server Error. Contact IT manager !!!");
    } else if (error.response?.status === 403) {
      errorFunction("Permission Denied. Contact IT manager !!!");
    } else if (error.response?.status === 404) {
      errorFunction("Page Not Found !!!!!");
    }
    //unauthorized user
    else if (error.response?.status === 401 || error.message === "Invalid token specified") {
      store.dispatch(authError());
    }

    // If not a 401 or refresh failed, reject the promise
    return Promise.reject(error);
  }
);

export default axiosInstance;
