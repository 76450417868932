import React, { useRef } from "react";
import { useSelector } from "react-redux";
import ColumnResize from "react-table-column-resizer";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import LodingListingSkeleton from "../../../Components/Skeleton/LodingListingSkeleton";
import Status from "../../../Components/Status";
import { PERMISSIONS } from "../../../Utils/permission";
import { getSpecificUser } from "../Redux/thunk";
import { userEditSuccess } from "../Redux/userSlice";

const User = ({ dispatch, setPostsPerPage, setShowUserModal }) => {
  const listInnerRef = useRef(null);
  const next = useSelector((state) => state.user.next);
  const users = useSelector((state) => state.user.users);
  const loadingUser = useSelector((state) => state.user.loadingUser);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const updateUserPermission = isSuperuser || permissions.includes(PERMISSIONS.user.updateUser);

  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 1) {
      if (next) {
        scrollToEnd();
      }
    }
  };

  const handleEdit = async (id) => {
    dispatch(userEditSuccess(id));
    await dispatch(getSpecificUser(id));
    setShowUserModal(true);
  };

  return (
    <div className="flex-grow-1 w-100 p-0 m-0 d-flex" style={{ overflow: "hidden" }}>
      <div className="table-scrollable w-100 p-0 mt-2" onScroll={handleScroll} ref={listInnerRef}>
        <table className="">
          <thead>
            <tr>
              <th>SN</th>
              <ColumnResize id={1} className="columnResizer" />
              <th>User Name</th>
              <ColumnResize id={2} className="columnResizer" minWidth={120} />
              <th>Emp No.</th>
              <ColumnResize id={3} className="columnResizer" minWidth={120} />
              <th>Branch</th>
              <ColumnResize id={5} className="columnResizer" minWidth={120} />
              <th>Email</th>
              <ColumnResize id={6} className="columnResizer" minWidth={120} />
              <th>Phone No.</th>
              <ColumnResize id={7} className="columnResizer" minWidth={120} />
              <th>Func. Deg.</th>
              <ColumnResize id={8} className="columnResizer" minWidth={120} />
              <th>Line Manage 1</th>
              <ColumnResize id={9} className="columnResizer" minWidth={120} />
              <th>Line Manage 2</th>
              <ColumnResize id={9} className="columnResizer" minWidth={120} />
              <th>Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, i) => {
              const { id, userName, employeeNo, branch, email, mobileNo, lineManage1, lineManage2, isActive } = user;

              return (
                <tr key={id} onDoubleClick={() => handleEdit(id)} style={{ cursor: "pointer" }}>
                  <td>{i + 1}</td>
                  <td className="column_resizer_body" />
                  <td>{userName ? userName : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>{employeeNo ? employeeNo : "N/A"}</td>
                  <td className="column_resizer_body" />

                  <td>{branch ? branch.name : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>{email ? email : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>{mobileNo ? mobileNo : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>{lineManage1 ? lineManage1 : "N/A"}</td>
                  <td className="column_resizer_body" />
                  <td>{lineManage2 ? lineManage2 : "N/A"}</td>
                  <td className="column_resizer_body" />

                  <td>
                    <Status active={isActive} />
                  </td>
                  <td className="column_resizer_body" />
                  <td>{updateUserPermission && <DetailActionButton type={"edit"} onClick={() => handleEdit(id)} />}</td>
                </tr>
              );
            })}
            {loadingUser && <LodingListingSkeleton numberOfColumns={14} />}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default User;
