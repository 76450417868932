import { createSlice } from "@reduxjs/toolkit";
import { getAllCollectionAndRecovery, getCollectionAndRecovery, handleSearch } from "./thunk";

const initialState = {
  collectionAndRecovery: [],
  edit: false,
  loading: false,
  previous: null,
  next: null,
};

export const collectionAndRecoverySlice = createSlice({
  name: "collectionAndRecovery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCollectionAndRecovery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCollectionAndRecovery.fulfilled, (state, action) => {
      state.collectionAndRecovery = action.payload.results;
      state.loading = false;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getAllCollectionAndRecovery.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCollectionAndRecovery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionAndRecovery.fulfilled, (state, action) => {
      state.collectionAndRecovery = action.payload.results;
      state.loading = false;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
      state.next = action.payload.next;
    });
    builder.addCase(getCollectionAndRecovery.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(handleSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.collectionAndRecovery = action.payload?.results;
      state.count = action.payload?.count;
      state.next = action.payload?.next;
      state.loading = false;
    });
    builder.addCase(handleSearch.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {} = collectionAndRecoverySlice.actions;
export default collectionAndRecoverySlice.reducer;
