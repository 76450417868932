import React from "react";
import "./nodata.css";
import { MdOutlineFitbit } from "react-icons/md";

const Nodata = ({ title, onClick }) => {
  return (
    <div className="no-data-wrapper mt-2 flex-column border p-4 d-flex justify-content-center align-items-center">
      <MdOutlineFitbit size={64} color="#008eb0" />
      <h5 className="p-2">No {title} yet</h5>
      <p className="">Create a {title} and it will show up here.</p>
      {title === "Inspection"  ? (
        ""
      ) : (
        <button onClick={onClick} className="btn create-btn">
          Create
        </button>
      )}
    </div>
  );
};

export default Nodata;
