import React, { useRef } from "react";
import { AiOutlineHolder } from "react-icons/ai";
import { useSelector } from "react-redux";
import ColumnResize from "react-table-column-resizer";
import DetailActionButton from "../../../Components/Icons/DetailButtonIcon.js";
import LodingListingSkeleton from "../../../Components/Skeleton/LodingListingSkeleton.js";

const SystemActivityListing = ({ setPostsPerPage, postsPerPage, setShowLoanModal }) => {
  const listInnerRef = useRef();
  const { activities, next, loading } = useSelector((state) => state.systemActivity);
  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  // const viewPermission = permissions.includes("view_loan_info") || isSuperuser;

  const scrollToEnd = () => {
    setPostsPerPage((prev) => prev + 10);
  };

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 1) {
      if (next) {
        scrollToEnd();
      }
    }
  };

  return (
    <>
      <div className="flex-grow-1 w-100 p-0 m-0 d-flex" style={{ overflow: "hidden" }}>
        <div className="table-scrollable w-100 p-0 mt-2" onScroll={handleScroll} ref={listInnerRef}>
          <table className="">
            <thead>
              <tr>
                <th>SN</th>
                <ColumnResize id={1} className="columnResizer" />

                <th className="text-left">Activity</th>
                <ColumnResize id={2} className="columnResizer" />

                <th className="text-left">Module</th>
                <ColumnResize id={4} className="columnResizer"></ColumnResize>

                <th className="text-left">ID</th>
                <ColumnResize id={4} className="columnResizer"></ColumnResize>

                <th className="text-left">TimeStamp</th>
                <ColumnResize id={4} className="columnResizer"></ColumnResize>

                <th>User</th>
                <ColumnResize id={5} className="columnResizer">
                  <AiOutlineHolder />
                </ColumnResize>
                <th>Description</th>
                <ColumnResize id={6} className="columnResizer">
                  <AiOutlineHolder />
                </ColumnResize>

                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {activities?.map((activity, index) => {
                const { id, eventType, modelName, objectId, timestamp, user, description } = activity;

                return (
                  <React.Fragment key={index}>
                    <tr key={id}>
                      <td>{index + 1}</td>
                      <td className="column_resizer_body" />
                      <td className="text-left">{eventType || "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td className="text-left">{modelName || "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td className="text-left">{objectId || "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td className="text-left">{new Date(timestamp).toDateString() || "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td>{user?.userName || "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td>{description || "N/A"}</td>
                      <td className="column_resizer_body" />
                      <td>
                        <DetailActionButton type={"edit"} onClick={() => {}} />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              {loading && <LodingListingSkeleton numberOfColumns={13} />}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SystemActivityListing;
