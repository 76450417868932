import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { errorFunction, successFunction } from "../../../Components/Alert/Alert";
import Button from "../../../Components/Buttons/Button";
import AsyncSelect from "../../../Components/CommonAsyncSelectField/AsyncSelect";
import Checkbox from "../../../Components/CommonCheckbox/Checkbox";
import Dropzone from "../../../Components/CommonDropzone/Dropzone";
import Select from "../../../Components/CommonSelectField/Select";
import Loader from "../../../Components/Loader";
import TextField from "../../../Components/TextField/TextField";
import Thumb from "../../../Components/Thumb";
import axiosInstance from "../../../Utils/axios";
import { fiscalSessionTypes } from "../../../Utils/constants";
import { SUPPORTED_FORMATS } from "../../../Utils/image";
import { PERMISSIONS } from "../../../Utils/permission";
import { createOrganization, getOrganization, updateOrganization } from "../Redux/thunk";

const CreateOrganization = ({ dispatch, postsPerPage, setShowUserSetupModal }) => {
  const { organization, loading, loadingUpdated, edit } = useSelector((state) => state.organization);
  const [logo, setLogo] = useState(null);
  const [signature, setSignature] = useState(null);
  const [stamp, setStamp] = useState(null);

  const { permissions, isSuperuser } = useSelector((state) => state.auth);
  const addOrUpdatePermission =
    isSuperuser || permissions.includes(PERMISSIONS.organizationSetup.createOrganizationSetup) || permissions.includes(PERMISSIONS.organizationSetup.updateOrganizationSetup);

  const initialState = {
    name: edit ? organization?.name : "",
    address: edit ? organization?.address : "",
    phoneNo: edit ? organization?.phoneNo : "",
    email: edit ? organization?.email : "",
    country: edit ? organization?.country : null,
    currency: edit ? organization?.currency : null,
    fiscalSessionType: edit ? fiscalSessionTypes?.find((type) => type.id === organization.fiscalSessionType) : null,
    logo: "",
    signature: "",
    stamp: "",
    active: true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required.").min(1).max(100),
    address: Yup.string().max(100),
    phoneNo: Yup.string().max(15),
    email: Yup.string().email("Please enter valid email address.").max(70),
    fiscalSessionType: Yup.object().required("Required!"),
    country: Yup.object().required("Required!").typeError("Required!"),
    currency: Yup.object().required("Required!").typeError("Required!"),
    logo: Yup.mixed()
      .test("fileSize", "File must be less than 20kb", (file) => !file || file.size <= 20 * 1024)
      .test("fileType", "Unsupported File Format.", (file) => !file || SUPPORTED_FORMATS.includes(file.type)),
    signature: Yup.mixed()
      .test("fileSize", "File must be less than 20kb", (file) => !file || file.size <= 20 * 1024)
      .test("fileType", "Unsupported File Format.", (file) => !file || SUPPORTED_FORMATS.includes(file.type)),
    stamp: Yup.mixed()
      .test("fileSize", "File must be less than 20kb.", (file) => !file || file.size <= 20 * 1024)
      .test("fileType", "Unsupported File Format.", (file) => !file || SUPPORTED_FORMATS.includes(file.type)),
    active: Yup.bool(),
  });

  const onSubmit = (values) => {
    const { country, currency, fiscalSessionType, logo, stamp, signature } = values;
    const action = edit ? updateOrganization : createOrganization;
    const successMessage = edit ? "Organization updated successfully." : "Organization created successfully.";

    const organizationData = {
      ...values,
      country: country?.id,
      currency: currency?.id,
      fiscalSessionType: fiscalSessionType?.id,
      ...(edit && {
        id: organization?.id,
        logo: typeof logo === "object" ? logo : undefined,
        stamp: typeof stamp === "object" ? stamp : undefined,
        signature: typeof signature === "object" ? signature : undefined,
      }),
    };

    dispatch(action(edit ? { id: organization?.id, values: organizationData } : organizationData))
      .unwrap()
      .then(() => {
        successFunction(successMessage);
        dispatch(getOrganization(postsPerPage));
        setShowUserSetupModal(false);
      })
      .catch(errorFunction);
  };

  const loadOptionsCountry = async (search, loadOptions, { limit, offset }) => {
    const { data } = await axiosInstance(`/api/v1/core-app/country-list?search=${search}&offset=${offset}&limit=${limit}`);
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.next ? offset + 10 : offset,
        limit: 10,
      },
    };
  };

  const loadOptionsCurrency = async (search, loadOptions, { limit, offset }) => {
    const { data } = await axiosInstance(`/api/v1/core-app/currency?search=${search}&offset=${offset}&limit=${limit}`);
    return {
      options: data.results,
      hasMore: data.next ? true : false,
      additional: {
        offset: data.next ? offset + 10 : offset,
        limit: 10,
      },
    };
  };

  return (
    <>
      {(loading || loadingUpdated) && <Loader />}

      <Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formik) => (
          <Form autoComplete="off">
            <div className="create-organization-wrapper">
              <div className="row">
                <div className="col-8">
                  <div className="row">
                    {[
                      { name: "name", label: "Organization Name", placeholder: "Name", autoFocus: true },
                      { name: "address", label: "Address", placeholder: "Address" },
                      { name: "phoneNo", label: "Phone Number", placeholder: "98*********" },
                      { name: "email", label: "Email", placeholder: "Enter Email" },
                    ].map((field) => (
                      <div className="col-6" key={field.name}>
                        <div className="my-2">
                          <TextField
                            type="text"
                            name={field.name}
                            label={field.label}
                            required
                            formikRequired={formik?.errors[field.name] && formik?.touched[field.name]}
                            placeholder={field.placeholder}
                            onChange={(e) => formik.setFieldValue(field.name, e.target.value)}
                            autoFocus={field.autoFocus}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="col-6">
                      <div className="my-2">
                        <AsyncSelect
                          value={formik.values.country}
                          name="country"
                          label="Country"
                          required={true}
                          formikRequired={formik?.errors?.country && formik?.touched?.country}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          onChange={(selected) => formik.setFieldValue("country", selected)}
                          loadOptions={loadOptionsCountry}
                          additional={{ offset: 0, limit: 10 }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="my-2">
                        <AsyncSelect
                          value={formik.values.currency}
                          name="currency"
                          label="Currency"
                          required={true}
                          formikRequired={formik?.errors?.currency && formik?.touched?.currency}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          onChange={(selected) => formik.setFieldValue("currency", selected)}
                          loadOptions={loadOptionsCurrency}
                          additional={{ offset: 0, limit: 10 }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="my-2">
                        <Select
                          value={formik?.values?.fiscalSessionType}
                          name="fiscalSessionType"
                          label="Fiscal Session Type"
                          required={true}
                          formikRequired={formik?.errors?.fiscalSessionType && formik?.touched?.fiscalSessionType}
                          options={fiscalSessionTypes}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          onChange={(selected) => formik.setFieldValue("fiscalSessionType", selected)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row">
                    {[
                      { name: "logo", label: "Logo", state: logo, setState: setLogo },
                      { name: "stamp", label: "Organization Stamp", state: stamp, setState: setStamp },
                      { name: "signature", label: "Owner Signature", state: signature, setState: setSignature },
                    ].map((item) => (
                      <div className="col-6" key={item.name}>
                        <div className="my-2">
                          <Dropzone
                            name={item.name}
                            label={item.label}
                            removePhoto={() => {
                              formik.setFieldValue(item.name, "");
                              item.setState(null);
                            }}
                            onChange={(event) => {
                              formik.setFieldValue(item.name, event.target.files[0]);
                              let reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              reader.onloadend = () => item.setState([reader.result]);
                            }}
                            displayImage={
                              item.state ? <Thumb thumb={item.state} /> : organization && organization[item.name] && !item.state ? <Thumb thumb={organization[item.name]} /> : ""
                            }
                            error={formik.errors[item.name]}
                            text={"File must be less than 20kb"}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 my-2 d-flex justify-content-center align-items-center">
                <Checkbox name="active" label="Active" />
              </div>
            </div>
            <div className="col-12 p-0 text-right">
              <div className="mt-3 d-flex justify-content-end align-items-center">
                <Button
                  btnType="submit"
                  className="btn create-button"
                  createButton={true}
                  disabled={loadingUpdated || !addOrUpdatePermission}
                  title={edit ? "Update" : "Save"}
                  content={edit ? "Update" : "Save"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateOrganization;
