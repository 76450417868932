import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllCheckListQuestions,
  getAllCheckListQuestionsAnswers,
  getAllQuestions,
  getAllQuestionsAnswer,
  getInspections,
  getQuestions,
  getSpecificInspection,
  handleSearch,
} from "./thunk";

const initialState = {
  inspections: [],
  inspection: null,
  questions: [],
  answers: [],
  questionsAnswers: [],
  checkListQuestions: [],
  checkListQuestionsAnswers: [],
  edit: false,
  loading: false,
  loadingInspection: false,
  loadingAll: false,
  previous: null,
  next: null,
  loadingUpdated: false,
};

export const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Questions

    builder.addCase(getAllQuestions.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.questions = action.payload.results;
    });

    builder.addCase(getAllQuestions.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllQuestionsAnswer.pending, (state) => {
      // state.loading = true;
    });

    builder.addCase(getAllQuestionsAnswer.fulfilled, (state, action) => {
      // state.loading = false;
      state.answers = action.payload.results;
    });

    builder.addCase(getAllQuestionsAnswer.rejected, (state) => {
      // state.loading = false;
    });

    // Inspections
    builder.addCase(getInspections.pending, (state) => {
      state.loadingInspection = true;
    });

    builder.addCase(getInspections.fulfilled, (state, action) => {
      state.loadingInspection = false;
      state.inspections = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getInspections.rejected, (state) => {
      state.loadingInspection = false;
    });

    builder.addCase(getSpecificInspection.pending, (state) => {
      state.loadingInspection = true;
    });

    builder.addCase(getSpecificInspection.fulfilled, (state, action) => {
      state.loadingInspection = false;
      state.inspection = action.payload;
    });

    builder.addCase(getSpecificInspection.rejected, (state) => {
      state.loadingInspection = false;
    });

    // checkListQuestions

    builder.addCase(getAllCheckListQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.checkListQuestions = action.payload.results;
    });

    builder.addCase(getAllCheckListQuestions.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllCheckListQuestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCheckListQuestionsAnswers.pending, (state) => {
      state.loadingAll = true;
    });

    builder.addCase(handleSearch.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllCheckListQuestionsAnswers.fulfilled, (state, action) => {
      state.loadingAll = false;
      state.checkListQuestionsAnswers = action.payload.results;
    });

    builder.addCase(getAllCheckListQuestionsAnswers.rejected, (state) => {
      state.loadingAll = false;
    });

    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.inspections = action.payload.results;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.loading = false;
    });
    builder.addCase(handleSearch.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {} = inspectionSlice.actions;
export default inspectionSlice.reducer;
