import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";
import Modal from "../../../Components/Modal/Modal";

import DetailActionButton from "../../../Components/Icons/DetailButtonIcon";
import { editBusinessNature } from "../Redux/businessNatureSlice";
import { deleteBusinessNature, getBusinessNature, getSpecificBusinessNature } from "../Redux/thunk";
import { errorFunction, successFunction } from "../../../Components/Alert/Alert";
import CreateBusinessNature from "./CreateBusinessNature";
import Nodata from "../../Loan/Pages/NoData/Nodata";

const BusinessNatureListing = ({ masterData }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const { businessNatures, edit, businessNature } = useSelector((state) => state?.businessNature);

  return (
    <>
      <div>
        {businessNatures?.length > 0 ? (
          <div className="loan-info py-2  mt-2">
            <div className="table-scrollable">
              <table className="">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>BusinessNature</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {businessNatures?.map((fac, index) => {
                    const { name, id } = fac;
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{name}</td>
                        <td>
                          <DetailActionButton
                            type={"edit"}
                            onClick={async () => {
                              dispatch(editBusinessNature());
                              dispatch(getSpecificBusinessNature(id));
                              setCreateModal(true);
                            }}
                          />
                        </td>
                        <td>
                          <DetailActionButton
                            type={"delete"}
                            onClick={async () => {
                              dispatch(deleteBusinessNature(id))
                                .unwrap()
                                .then(() => {
                                  successFunction("Business Nature deleted Successfully.");
                                  dispatch(getBusinessNature(postsPerPage));
                                })
                                .catch((error) => {
                                  errorFunction(error);
                                });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Nodata title={"Business Nature"} onClick={() => setCreateModal(true)} />
        )}
      </div>
      {createModal && (
        <Modal
          size={"modal-lg"}
          showModal={createModal}
          setShowModal={setCreateModal}
          header={edit ? "Update Business Nature" : "Business Nature"}
          dispatch={setShowModal}
        >
          <CreateBusinessNature
            masterData={masterData}
            showModal={createModal}
            setShowModal={setCreateModal}
            postsPerPage={postsPerPage}
          />
        </Modal>
      )}
    </>
  );
};

export default BusinessNatureListing;
