import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get organizationHierarchy
export const getOrganizationHierarchy = createAsyncThunk(
  "organizationHierarchy/getOrganizationHierarchy",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getOrganizationHierarchy();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get organizationHierarchy.");
    }
  }
);

// get all organizationHierarchy
export const getAllOrganizationHierarchy = createAsyncThunk(
  "organizationHierarchy/getAllOrganizationHierarchy",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllOrganizationHierarchy();
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get organizationHierarchy.");
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk(
  "organizationHierarchy/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to get organizationHierarchy.");
    }
  }
);
// get next
export const getNext = createAsyncThunk(
  "organizationHierarchy/getNext",
  async (next, { rejectWithValue }) => {
    try {
      const { data } = await API.getNext(next);

      return data;
    } catch (error) {
      return rejectWithValue("Failed to get organizationHierarchy.");
    }
  }
);

// get particular page
export const getPageOrganizationHierarchy = createAsyncThunk(
  "organizationHierarchy/getPageOrganizationHierarchy",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageOrganizationHierarchy(
        number,
        postsPerPage
      );
      return data;
    } catch (error) {
      rejectWithValue("Failed to get organizationHierarchy.");
    }
  }
);

// create organizationHierarchy
export const createOrganizationHierarchy = createAsyncThunk(
  "organizationHierarchy/createOrganizationHierarchy",
  async (data, { rejectWithValue, dispatch }) => {
    const { name, prefix, parent, active, remarks } = data;
    try {
      const body = JSON.stringify({ name, prefix, parent, active, remarks });
      const { data } = await API.createOrganizationHierarchy(body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to create organizationHierarchy.");
    }
  }
);
export const updateOrganizationHierarchy = createAsyncThunk(
  "organizationHierarchy/updateOrganizationHierarchy",
  async (data, { rejectWithValue, dispatch }) => {
    const { id, values } = data;
    const { name, prefix, parent, active, remarks } = values;
    try {
      const body = JSON.stringify({ name, prefix, parent, active, remarks });
      const { data } = await API.updateOrganizationHierarchy(id, body);
      return data;
    } catch (error) {
      return rejectWithValue("Failed to update organization hierarchy.");
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "organizationHierarchy/handleSearch",
  async (data, { rejectWithValue }) => {
    const { search, postsPerPage } = data;
    try {
      const { data } = await API.handleSearch(search, postsPerPage);
      return data;
    } catch (error) {
      rejectWithValue("Failed to get organization hierarchy");
    }
  }
);
