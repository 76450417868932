import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getSystemActivities } from "./thunk";

const initialState = {
  activities: [],
  count: 0,
  loading: false,
  previous: null,
  next: null,
};

export const systemActivitySlice = createSlice({
  name: "systemActivity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSystemActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSystemActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.activities = action?.payload?.results;
      state.next = action?.payload?.next;
      state.previous = action?.payload?.previous;
      state.count = action?.payload?.count;
    });
    builder.addCase(getSystemActivities.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {} = systemActivitySlice.actions;
export default systemActivitySlice.reducer;
