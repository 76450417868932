import { editQuestion } from "./annexSlice";
import * as API from "./api";

import { createAsyncThunk } from "@reduxjs/toolkit";

// Async Thunk for Annexure
export const getAnnex = createAsyncThunk("annex/getAnnex", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getAnnex(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAllAnnex = createAsyncThunk("annex/getAllAnnex", async (annexType, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllAnnex(annexType);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAnnex = createAsyncThunk("annex/createAnnex", async (values, { rejectWithValue }) => {
  try {
    const { data } = await API.createAnnex(JSON.stringify(values));
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.msg[0]);
  }
});

export const updateAnnex = createAsyncThunk("annex/updateAnnex", async (updateData, { rejectWithValue }) => {
  const { id, values } = updateData;
  try {
    const { data } = await API.updateAnnex(id, JSON.stringify(values));
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.msg[0]);
  }
});

// Async Thunk for Question

export const getAllQuestions = createAsyncThunk("annex/getAllQuestions", async (data, { rejectWithValue }) => {
  const { id, questionType, postsPerPage, annexType } = data;
  try {
    const { data } = await API.getAllQuestions(id, questionType, postsPerPage, annexType);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getQuestion = createAsyncThunk("annex/getQuestion", async (id, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await API.getQuestion(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestion = createAsyncThunk("annex/createQuestion", async (body, { rejectWithValue }) => {
  try {
    const { data } = await API.createQuestion(body);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateQuestion = createAsyncThunk("annex/updateQuestion", async (data, { rejectWithValue }) => {
  const { id, createdData } = data;

  try {
    const newdata = createdData[0];

    // const body = JSON.stringify({ createdData });
    const { data } = await API.updateQuestion(id, newdata);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// Async Thunk for Answer
export const getAllAnswers = createAsyncThunk("annex/getAllAnswers", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllAnswers(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAnswer = createAsyncThunk("annex/getAnswer", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getAnswer(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAnswer = createAsyncThunk("annex/createAnswer", async (body, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await API.createAnswer(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.msg[0]);
  }
});

export const updateAnswer = createAsyncThunk("annex/updateAnswer", async (updateData, { rejectWithValue }) => {
  const { id, values } = updateData;
  const body = {
    answerDetails: values,
    isActive: true,
  };
  try {
    const { data } = await API.updateAnswer(id, body);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// Async Thunk for Question Answer
// export const getAllQuestionAnswers = createAsyncThunk(
//   "annex/getAllQuestionAnswers",
//   async (postsPerPage, { rejectWithValue }) => {
//     try {
//       const { data } = await API.getAllQuestionAnswers(postsPerPage);
//       return data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const getQuestionAnswer = createAsyncThunk("annex/getQuestionAnswer", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getQuestionAnswer(id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestionAnswer = createAsyncThunk("annex/createQuestionAnswer", async (body, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await API.createQuestionAnswer(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.msg[0]);
  }
});

export const updateQuestionAnswer = createAsyncThunk("annex/updateQuestionAnswer", async (updateData, { rejectWithValue }) => {
  const { id, values } = updateData;
  const body = {
    questionAnswerDetails: values,
    isActive: true,
  };
  try {
    const { data } = await API.updateQuestionAnswer(id, body);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSubQuestion = createAsyncThunk("annex/getSubQuestion", async (bodyData, { rejectWithValue }) => {
  try {
    const { data } = await API.getSubQuestion({ currentQuestionId: bodyData });
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const handleSearch = createAsyncThunk("annex/handleSearch", async (value, { rejectWithValue }) => {
  const { id, search, postsPerPage, questionType } = value;
  try {
    const { data } = await API.handleSearch(id, search, postsPerPage, questionType?.value);
    return data;
  } catch (error) {
    return rejectWithValue("Failed to get Leads");
  }
});
