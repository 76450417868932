import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllBusinessInspection,
  getBusinessInspection,
  createBusinessInspection,
  updateBusinessInspection,
} from "./thunk";

const initialState = {
  businessInspection: [],
  edit: false,
  businessInspectionDetail: null,
  loading: false,
  previous: null,
  next: null,
  loadingBusinessInspection: false,
  loadingMoreBusinessInspection: false,
};

export const businessInspectionSlice = createSlice({
  name: "business-inspection",
  initialState,
  reducers: {
    clearEditBusinessInspection: (state, action) => {
      // state.loan = state.loans.find((loan) => loan.id === action.payload);
      state.edit = false;
      state.loading = false;
    },
    editBusinessInspection: (state) => {
      state.edit = true;
    },
    businessInspectionClearData: (state) => {
      state.edit = false;
      state.businessInspectionDetail = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBusinessInspection.pending, (state) => {
      state.loadingBusinessInspection = true;
    });
    builder.addCase(getAllBusinessInspection.fulfilled, (state, action) => {
      state.loadingBusinessInspection = false;
      state.businessInspection = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });
    builder.addCase(getAllBusinessInspection.rejected, (state) => {
      state.loadingBusinessInspection = false;
    });
    // for get single business inspection
    builder.addCase(getBusinessInspection.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getBusinessInspection.fulfilled, (state, action) => {
      state.loading = false;
      state.businessInspectionDetail = action.payload.results;
      state.next = action.payload.next;
    });

    builder.addCase(getBusinessInspection.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createBusinessInspection.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createBusinessInspection.fulfilled, (state, action) => {
      state.loading = false;
      state.businessInspectionDetail = action.payload;
      state.next = action.payload.next;
    });

    builder.addCase(createBusinessInspection.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBusinessInspection.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateBusinessInspection.fulfilled, (state, action) => {
      state.loading = false;
      state.businessInspectionDetail = action.payload;
      state.next = action.payload.next;
    });

    builder.addCase(updateBusinessInspection.rejected, (state) => {
      state.loading = false;
      state.businessInspectionDetail = null;
      state.loading = false;
    });
  },
});

export const { clearEditBusinessInspection, editBusinessInspection, businessInspectionClearData } =
  businessInspectionSlice.actions;
export default businessInspectionSlice.reducer;
