import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllLoan,
  getCIFDetails,
  getCollateralHistory,
  getInspectionHistory,
  getLoan,
  getLoans,
  getSpecificLoanInfo,
  handleSearch,
} from "./thunk";

const initialState = {
  loans: [],
  collateralHistory: [],
  inspectionHistory: [],
  CIFDetails: null,
  edit: false,
  loan: null,
  loading: false,
  previous: null,
  next: null,
  loadingLoans: false,
  loadingMoreLoans: false,
};

export const loanSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    clearEditLoans: (state, action) => {
      // state.loan = state.loans.find((loan) => loan.id === action.payload);
      state.edit = false;
      state.loading = false;
    },
    editLoans: (state) => {
      state.edit = true;
    },
    loanClearData: (state) => {
      state.edit = false;
      state.loan = null;
      state.loading = false;
      state.CIFDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoans.pending, (state) => {
      state.loadingLoans = true;
    });
    builder.addCase(getLoans.fulfilled, (state, action) => {
      state.loadingLoans = false;
      state.loans = action.payload.results;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });
    builder.addCase(getLoans.rejected, (state) => {
      state.loadingLoans = false;
    });
    // for get all loans
    builder.addCase(getAllLoan.pending, (state) => {
      state.loadingLoans = true;
    });
    builder.addCase(getAllLoan.fulfilled, (state, action) => {
      state.loadingLoans = false;
      state.loans = action.payload.results;
      state.count = action.payload.count;
      state.next = action.payload.next;
    });

    builder.addCase(getAllLoan.rejected, (state) => {
      state.loadingLoans = false;
    });

    // for get single loan
    builder.addCase(getLoan.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getLoan.fulfilled, (state, action) => {
      state.loading = false;
      state.loan = action.payload;
      state.next = action.payload.next;
    });

    builder.addCase(getLoan.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(handleSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(handleSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.loans = action.payload.results;
      state.count = action.payload.count;
      state.next = action.payload.next;
    });
    builder.addCase(handleSearch.rejected, (state) => {
      state.loading = false;
    });

    // for history
    builder.addCase(getCollateralHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollateralHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.collateralHistory = action.payload.results;
    });
    builder.addCase(getCollateralHistory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getInspectionHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInspectionHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.inspectionHistory = action.payload.results;
    });
    builder.addCase(getInspectionHistory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSpecificLoanInfo.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificLoanInfo.fulfilled, (state, action) => {
      state.loan = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificLoanInfo.rejected, (state) => {
      state.loading = false;
    });

    // New APIs
    builder.addCase(getCIFDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCIFDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.CIFDetails = action.payload;
    });
    builder.addCase(getCIFDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { clearEditLoans, editLoans, loanClearData } = loanSlice.actions;
export default loanSlice.reducer;
