import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonCreateButton from "../../../Components/CommonCreateButton/CommonCreateButton";
import CommonPageHeader from "../../../Components/CommonPageHeader/CommonPageHeader";
import ListingSkeleton from "../../../Components/Skeleton/ListingSkeleton";
import { setShowModal } from "../../../Redux/Layout/layoutSlice";
import useDebounce from "../../../Utils/Hooks/useDebounce";
import { getCollateralType, handleSearch } from "../Redux/thunk";
import CollateralTypeListing from "./CollateralTypeListing";
import CreateCollateralType from "./CreateCollateralType";

const Modal = lazy(() => import("../../../Components/Modal/Modal"));

const title = "Collateral Type";
const types = "collateral-type";

const CollateralType = () => {
  const dispatch = useDispatch();
  const loadingCollateralType = useSelector((state) => state.collateralType.loadingCollateralType);
  const collateralTypes = useSelector((state) => state.collateralType.collateralTypes);
  const count = useSelector((state) => state.collateralType.count);
  const edit = useSelector((state) => state.collateralType.edit);
  const { showModal } = useSelector((state) => state.layout);
  const [showCollateralTypeModal, setShowCollateralTypeModal] = useState(false);

  const [search, setSearch] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(20);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (search === "") {
      dispatch(getCollateralType(postsPerPage));
    } else {
      dispatch(handleSearch({ search, postsPerPage }));
    }
  }, [postsPerPage, debouncedSearch, dispatch, search]);

  return (
    <>
      <div className="department-wrapper">
        <CommonPageHeader
          title={title}
          dispatch={dispatch}
          showModal={showModal}
          search={search}
          setSearch={setSearch}
          loading={loadingCollateralType}
          data={collateralTypes}
          count={count}
        />

        {loadingCollateralType && <ListingSkeleton />}
        {!loadingCollateralType && (
          <CollateralTypeListing dispatch={dispatch} showModal={showModal} setPostsPerPage={setPostsPerPage} setCollateralTypeModal={setShowCollateralTypeModal} />
        )}
      </div>
      <CommonCreateButton types={types} showModal={showCollateralTypeModal} setShowModal={setShowCollateralTypeModal} />
      {showCollateralTypeModal && (
        <Suspense fallback={<div></div>}>
          <Modal
            dispatch={setShowModal}
            showModal={showCollateralTypeModal}
            setShowModal={setShowCollateralTypeModal}
            header={edit ? "Update Collateral Type" : "Add Collateral Type"}
            types={types}
            edit={edit}
            size={"modal-md"}
            // clearAction={clearAllDepartment}
          >
            <CreateCollateralType dispatch={dispatch} showModal={showCollateralTypeModal} postsPerPage={postsPerPage} setShowModal={setShowCollateralTypeModal} />
          </Modal>
        </Suspense>
      )}
    </>
  );
};

export default CollateralType;
