import axiosInstance from "../../../Utils/axios";

// obtaining the paginated data
export const getFacility = (postsPerPage) =>
  axiosInstance.get(`api/v1/lead-app/facility-type?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all data
export const getAllFacility = () => axiosInstance.get(`api/v1/lead-app/facility-type?ordering=-id`);

export const getSpecificFacility = (id) => axiosInstance.get(`api/v1/lead-app/facility-type/${id}`);
//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//creating function

export const createFacility = (body) => axiosInstance.post(`api/v1/lead-app/facility-type`, body);
export const updateFacility = (id, body) =>
  axiosInstance.patch(`api/v1/lead-app/facility-type/${id}`, body);
export const deleteFacility = (id) =>
  axiosInstance.delete(`api/v1/lead-app/facility-type/${id}`, id);
//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(
    `api/v1/lead-app/facility-type?offset=0&limit=${postsPerPage}&search=${search}`
  );
