import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getCollateralType,
  getAllCollateralType,
  getNext,
  getPrevious,
  createCollateralType,
  handleSearch,
  getSpecificCollateralType,
} from "./thunk";

const initialState = {
  collateralTypes: [],
  edit: false,
  collateralType: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingCollateralType: false,
};

export const collateralTypeSlice = createSlice({
  name: "collateralType",
  initialState,
  reducers: {
    editCollateralType: (state) => {
      state.edit = true;
    },
    clearEditCollateralType: (state) => {
      state.edit = false;
      state.collateralType = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCollateralType.pending, (state) => {
      state.loadingCollateralType = true;
    });
    builder.addCase(getCollateralType.fulfilled, (state, action) => {
      state.loadingCollateralType = false;
      state.edit = false;
      state.collateralTypes = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getCollateralType.rejected, (state) => {
      state.loadingCollateralType = false;
      state.edit = false;
    });
    builder.addCase(createCollateralType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCollateralType.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createCollateralType.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(getSpecificCollateralType.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificCollateralType.fulfilled, (state, action) => {
      state.collateralType = action.payload;
      state.loading = false;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
    });

    builder.addCase(getSpecificCollateralType.rejected, (state) => {
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(
        getAllCollateralType.pending,
        getPrevious.pending,
        getNext.pending,

        handleSearch.pending
      ),
      (state) => {
        state.loadingCollateralType = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllCollateralType.fulfilled,
        getPrevious.fulfilled,
        getNext.fulfilled,

        handleSearch.fulfilled
      ),
      (state, action) => {
        state.loadingCollateralType = false;
        state.collateralTypes = action.payload.results;
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllCollateralType.rejected,
        getPrevious.rejected,
        getNext.rejected,
        handleSearch.rejected
      ),
      (state) => {
        state.loadingCollateralType = false;
      }
    );
  },
});
export const { editCollateralType, clearEditCollateralType } = collateralTypeSlice.actions;

export default collateralTypeSlice.reducer;
